import moment from "moment";
import { utcFormater } from "../helperFunction/dateConverter";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
const useCreateProgram = (handleClick) => {
  const status = [
    {
      value: "1",
      label: "Active",
    },
    {
      value: "0",
      label: "Inactive",
    },
  ];

  const columns = [
    {
      field: "no",
      headerName: "S No",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        const rowId = params.row.id;
        const sortedRowIds = params.api.getSortedRowIds();
        const rowIndex = sortedRowIds.indexOf(rowId) + 1;
        return rowIndex;
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "category",
      headerName: "Category",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    // {
    //   field: "sub_category",
    //   headerName: "Sub Category",
    //   width: 200,
    //   headerClassName: "super-app-theme--header",
    // },
    {
      field: "eff_end_date",
      headerName: "Start Date",
      type: "date",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueFormatter: (params) => {
        return params.row?.eff_end_date;
      },
      renderCell: (params) =>
        params.row?.eff_end_date ? utcFormater(params.row.eff_end_date)[0]: "-",
    },
    {
      field: "active_flg",
      headerName: "Status",
      type: "singleSelect",
      width: 200,
      headerClassName: "super-app-theme--header",
      valueOptions: status.map((e) => {
        return {
          value: e.value,
          label: e.label,
        };
      }),
      renderCell: (params) => (params.row?.active_flg ? "Active" : "Inactive"),
    },
    {
      field: "associatedClientCount",
      headerName: "Total Count",
      width: 200,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "version",
      headerName: "Version",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      width: 200,
      headerClassName: "super-app-theme--header",
      type: "date",
      valueFormatter: (params) => {
        return params.row?.createdAt;
      },
      renderCell: (params) =>
        params.row?.createdAt ? utcFormater(params.row.createdAt)[0] : "-",
    },
    {
      field: "created_by",
      headerName: "Created by",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "updatedAt",
      headerName: "Updated Date",
      width: 200,
      headerClassName: "super-app-theme--header",
      type: "date",
      valueFormatter: (params) => {
        return params.row?.updatedAt;
      },
      renderCell: (params) =>
        params.row?.updatedAt ? utcFormater(params.row.updatedAt)[0]:"-"
    },
    {
      field: "updated_by",
      headerName: "Updated by",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "action",
      headerName: "Actions",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div
            className="action-btn"
            onClick={(e) => handleClick(e, params.row)}
          >
            <MoreHorizIcon />
          </div>
        );
      },
    },
  ];
  return { columns };
};
export default useCreateProgram;
