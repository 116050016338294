import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TablePagination,
  Collapse,
  CardContent,
  Card,
  CardActions,
  IconButton,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";

const useStyles = makeStyles({
  root: {
    overflowX: "auto",
  },
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      border: "1px solid #D0D0D0",
      overflowX: "auto",
    },
  },
});
const InteractionList = (props) => {
  const classes = useStyles();
  let params = useParams();
  let history = useHistory();
  let operator = JSON.parse(window.localStorage.getItem("operator"));

  const [expand, setExpand] = useState(true);
  const [page, setPage] = useState(2);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [isLead, setIsLead] = useState(false);

  const toggleCollapse = () => {
    setExpand(!expand);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (
      window.location.pathname.includes("Leads") ||
      window.location.pathname.includes("lead")
    ) {
      setIsLead(true);
    }
  }, []);

  return (
    <div>
      <Card
        sx={{ width: "-webkit-fill-available !important", overflowX: "scroll" }}
      >
        <CardActions>
          <Typography
            sx={{ fontSize: "18px", fontWeight: "600", fontFamily: "Poppins" }}
          >
            Interaction
          </Typography>
          <IconButton
            disableRipple
            onClick={toggleCollapse}
            aria-expanded={expand}
            aria-label="Show more"
            sx={{ marginLeft: "85pc" }}
          >
            {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </CardActions>
        <Collapse in={expand} timeout="auto" unmountOnExit>
          <CardContent>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableHead sx={{ backgroundColor: "#F4F4F4" }}>
                  <TableRow>
                    <TableCell align="left">
                      <b>Serial No</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>Lead Id</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>Phone No</b>
                    </TableCell>
                    <TableCell>
                      <b>Category</b>
                    </TableCell>
                    {isLead && (
                      <TableCell align="left">
                        <b>Stage</b>
                      </TableCell>
                    )}

                    <TableCell align="left">
                      <b>Type</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>Duration</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>Date</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>Time</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>Next Follow Up</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>Contacted By</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>Notes</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>Telephony</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>Recording</b>
                    </TableCell>
                    {/* <TableCell align="left">
                      <b>Disposition</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>Sub Disposition</b>
                    </TableCell>
                    <TableCell align="left">
                      <b>Grand Disposition</b>
                    </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props?.interactionList.length !== 0 ? (
                    props?.interactionList.map((log, index) => (
                      <TableRow sx={{ color: "#6B6B6B" }} key={log.id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{log.leadId ? log.leadId : "-"}</TableCell>
                        <TableCell>{log.to ? log.to : "-"}</TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ color: "#6B6B6B" }}
                        >
                          {log.category !== null ? log.category : "-"}
                        </TableCell>

                        {isLead && (
                          <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                            {log.stage !== null ? log.stage : "-"}
                          </TableCell>
                        )}

                        <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                          {log.interactionType !== null
                            ? log.interactionType
                            : "-"}
                        </TableCell>
                        <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                          {log.callDuration !== null ||
                          log.callDuration !== undefined
                            ?  (log.callDuration / 60).toFixed(1)
                            : "-"}
                        </TableCell>

                        <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                          {log.interaction_on
                            ? moment(log.interaction_on).format("DD/MM/YYYY")
                            : "-"}
                        </TableCell>
                        <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                          {log.interaction_on
                            ? moment(log.interaction_on).format("HH:mm")
                            : "-"}
                        </TableCell>

                        <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                          {log.next_followup_on !== null
                            ? moment(log.next_followup_on).format("DD/MM/YYYY")
                            : "-"}
                        </TableCell>
                        <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                          {log.User !== null ? log.User : "-"}
                        </TableCell>
                        <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                          {log.reviewNote !== null ? log.reviewNote : "-"}
                        </TableCell>
                        <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                          {log.telephony !== null ? log.telephony : "-"}
                        </TableCell>
                        <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                          {log.recordingURL !== null ? (
                            <audio controls>
                              <source src={log.recordingURL} type="audio/mp3" />
                            </audio>
                          ) : (
                            "-"
                          )}
                        </TableCell>

                        {/* <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                          {log.disposition !== null ? log.disposition : "-"}
                        </TableCell>
                        <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                          {log.clientSubDisposition !== null &&
                            log.clientSubDisposition}
                          {log.subDisposition !== null && log.subDisposition}
                          {log.clientSubDisposition === null ||
                            log.subDisposition === null ||
                            "-"}
                        </TableCell>

                        <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                          {log.grandDisposition !== null
                            ? log.grandDisposition
                            : "-"}
                        </TableCell> */}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={13} align="center">
                        No Results Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <TablePagination
              sx={{ margin: "30px !important" }}
              component="div"
              count={100}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
};

export default InteractionList;
