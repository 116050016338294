import {
  Typography,
  Grid,
  Button,
  Autocomplete,
  TextField,
  Checkbox,
  Paper,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Card from "../../../components/Card/Card";
import { useHistory, useLocation } from "react-router-dom";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";

import TextBox from "../../../components/TextFields/TextBox";
import MultiSelectBox from "../../../components/TextFields/MultiSelectBox";
import DatePickerBox from "../../../components/TextFields/DatePickerBox";
import SelectBox from "../../../components/TextFields/SelectBox";
import RadioButtonGroup from "../../../components/TextFields/RadioButtonGroup";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import { toast } from "react-toastify";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const options = [
  { label: "Dietitian", value: "dietitian" },
  { label: "Trainer", value: "trainer" },
];

const initialState = {
  assignedTo: "",
  ruleName: "",
  description: "",
  sequenceNo: "",
  startDate: "",
  endDate: "",
  groupOne: [],
  groupTwo: [],
  groupThree: [],
  conditionList: [
    {
      selectList: "",
      selectCondition: "",
      selectType: "",
      multiSelectType: [],
    },
  ],
};

const AddRule = () => {
  const history = useHistory();
  const { state } = useLocation();
  const currentRow = state && state.currentRow;
  const isEdit = state && state.isEdit;
  const isView = state && state.isView;

  const [isLoading, setIsLoading] = useState(false);

  const [dropDownValues, setDropDownValues] = useState({});

  const form = useForm({
    defaultValues: isEdit || isView ? currentRow : initialState,
  });

  const { handleSubmit, control, watch, reset, getValues } = form;
  const currentSelectedList = watch("conditionList");
  const currentAssignedTo = watch("assignedTo");
  const groupOne = watch("groupOne");
  const groupTwo = watch("groupTwo");
  const groupThree = watch("groupThree");

  const getFilteredOptions = (groupIndex) => {
    if (!currentAssignedTo) return [];
    const list =
      currentAssignedTo === "dietitian"
        ? dropDownValues.dietitianList
        : dropDownValues.trainerList;

    const otherSelectedIds = [
      ...(groupIndex !== 0 ? groupOne : []),
      ...(groupIndex !== 1 ? groupTwo : []),
      ...(groupIndex !== 2 ? groupThree : []),
    ].map((item) => item.id);

    return list.filter((item) => !otherSelectedIds.includes(item.id));
  };

  useEffect(() => {
    if (!isEdit && !isView) {
      reset({
        ...getValues(),
        groupOne: [],
        groupTwo: [],
        groupThree: [],
      });
    }
  }, [currentAssignedTo]);

  const {
    fields: fieldCondition,
    append: appendCondition,
    remove: removeCondition,
  } = useFieldArray({ name: "conditionList", control });

  const handleAddCondition = () => {
    if (fieldCondition.length < dropDownValues.conditionList.length) {
      appendCondition({
        selectCondition: "",
        multiSelectType: [],
        selectType: "",
      });
    }
  };

  const handleRemoveAddCondition = (index) => {
    if (isView) return;
    removeCondition(index);
  };

  const getAvailableConditionOptions = (index) => {
    const selectedConditions = currentSelectedList
      ?.map((item) => item?.selectList)
      .filter((_, i) => i !== index);

    const filterList = dropDownValues.conditionList?.filter(
      (option) => !selectedConditions?.includes(option.id)
    );

    return filterList;
  };

  const getSelectTypeOptions = (selectedList) => {
    let selectedTypeOption;
    let isMultiSelect = true;
    let condition = dropDownValues.defaultCondition;

    switch (selectedList) {
      case "shiftTiming": {
        selectedTypeOption = dropDownValues.shiftTimingList;
        break;
      }
      case "program": {
        selectedTypeOption = dropDownValues.metals;
        isMultiSelect = false;
        break;
      }
      case "language": {
        selectedTypeOption = dropDownValues.languagesList;
        break;
      }
      case "medicalCondition": {
        selectedTypeOption = dropDownValues.medicalConditions;
        break;
      }
      case "preferredCuisine": {
        selectedTypeOption = dropDownValues.preferedCuisine;
        break;
      }
    }

    return [selectedTypeOption, isMultiSelect, condition];
  };

  const onSubmit = async (formdata) => {
    formdata.groupOne = JSON.stringify(formdata.groupOne);
    formdata.groupTwo = JSON.stringify(formdata.groupTwo);
    formdata.groupThree = JSON.stringify(formdata.groupThree);

    console.log(formdata, "testttt");

    let request;
    setIsLoading(true);

    try {
      if (!isEdit) {
        request = await axiosFetch({
          url: `/api/v1/clientAutomation`,
          method: "post",
          requestConfig: formdata,
        });
      } else {
        request = await axiosFetch({
          url: `/api/v1/clientAutomation`,
          method: "put",
          requestConfig: formdata,
        });
      }

      if (request.status === 201 || request.status === 200) {
        setIsLoading(false);
        toast.success(request.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        reset();
        history.push({
          pathname: "/Settings/automation",
          state: {
            tab: 1,
          },
        });
      } else {
        setIsLoading(false);
        toast.error(request.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleBack = () => {
    history.push({
      pathname: "/Settings/automation",
      state: {
        tab: 1,
      },
    });
  };

  useEffect(async () => {
    const request = await axiosFetch({
      url: `api/v1/dropdownList`,
      method: "get",
    });

    if (request.status === 200) {
      setDropDownValues(request.data?.dropDownList);
    }
  }, []);

  return (
    <Card>
      <Typography
        sx={{
          display: "inline-flex",
          alignItems: "center",
          width: "cont",
          gap: "5px",
          fontSize: "18px",
          fontWeight: "600",
          cursor: "pointer",
        }}
        onClick={handleBack}
      >
        <KeyboardBackspaceIcon />
        Add Rule
      </Typography>
      <form className="dateForm" onSubmit={handleSubmit(onSubmit)}>
        {Object.keys(dropDownValues).length > 0 && (
          <Grid container spacing={4}>
            <Grid item md={12} sx={{ mt: "20px" }}>
              <RadioButtonGroup
                control={control}
                name="assignedTo"
                options={options}
                isRequired={true}
                disabled={isView || isEdit}
              />
            </Grid>
            <Grid item md={6}>
              <Grid container spacing={4}>
                <Grid item md={12}>
                  <Typography sx={{ fontWeight: 600, mb: "10px" }}>
                    Rule Name<span style={{ color: "red" }}>*</span>
                  </Typography>

                  <TextBox
                    control={control}
                    name={"ruleName"}
                    isRequired={{
                      required: "required",
                      maxLength: {
                        value: 100,
                        message: "Maximum 100 characters allowed",
                      },
                    }}
                    disabled={isView}
                  />
                </Grid>
                <Grid item md={4}>
                  <Typography sx={{ fontWeight: 600, mb: "10px" }}>
                    Sequence No<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <TextBox
                    control={control}
                    name={"sequenceNo"}
                    type="number"
                    isRequired={{
                      required: "required",
                    }}
                    disabled={isView}
                  />
                </Grid>
                <Grid item md={4}>
                  <Typography sx={{ fontWeight: 600, mb: "10px" }}>
                    Start Date<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <DatePickerBox
                    control={control}
                    name={"startDate"}
                    isRequired
                    disabled={isView}
                  />
                </Grid>
                <Grid item md={4}>
                  <Typography sx={{ fontWeight: 600, mb: "10px" }}>
                    End Date<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <DatePickerBox
                    control={control}
                    name={"endDate"}
                    isRequired
                    disabled={isView}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6}>
              <Typography sx={{ fontWeight: 600, mb: "10px" }}>
                Description<span style={{ color: "red" }}>*</span>
              </Typography>
              <TextBox
                control={control}
                name={"description"}
                isRequired={{
                  required: "required",
                  maxLength: {
                    value: 500,
                    message: "Maximum 500 characters allowed",
                  },
                }}
                disabled={isView}
              />
            </Grid>
            <Grid
              item
              md={12}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                type="button"
                variant="contained"
                sx={{
                  backgroundColor: "#2a2a2a",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#000",
                  },
                }}
                onClick={handleAddCondition}
              >
                Add Conditions
              </Button>
            </Grid>

            {fieldCondition.map((field, index) => {
              const selectedList = currentSelectedList?.[index]?.selectList;
              const selectedCondition =
                currentSelectedList?.[index]?.selectCondition;
              const [selectedTypeOption, isMultiSelect, condition] =
                getSelectTypeOptions(selectedList);

              const filteredConditionList = getAvailableConditionOptions(index);

              return (
                <Grid item md={12} key={field.id}>
                  <Grid container spacing={4}>
                    <Grid item md={4}>
                      <SelectBox
                        name={`conditionList.${index}.selectList`}
                        control={control}
                        menuList={filteredConditionList}
                        isRequired
                        inputLabel={"Condition"}
                        disabled={isView}
                      />
                    </Grid>
                    {selectedTypeOption && (
                      <Grid item md={3}>
                        <SelectBox
                          name={`conditionList.${index}.selectCondition`}
                          control={control}
                          menuList={condition}
                          isRequired
                          inputLabel={"Condition"}
                          disabled={isView}
                        />
                      </Grid>
                    )}
                    {selectedCondition && isMultiSelect && (
                      <Grid item md={4}>
                        <MultiSelectBox
                          name={`conditionList.${index}.multiSelectType`}
                          control={control}
                          menuList={selectedTypeOption}
                          isRequired
                          inputLabel={"Select"}
                          disabled={isView}
                        />
                      </Grid>
                    )}
                    {selectedCondition && !isMultiSelect && (
                      <Grid item md={4}>
                        <SelectBox
                          name={`conditionList.${index}.selectType`}
                          control={control}
                          menuList={selectedTypeOption}
                          isRequired
                          inputLabel={"Condition"}
                          disabled={isView}
                        />
                      </Grid>
                    )}
                    <Grid
                      item
                      md={1}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        height: "70px",
                      }}
                    >
                      <CloseIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleRemoveAddCondition(index)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}

            {["groupOne", "groupTwo", "groupThree"].map((group, index) => (
              <Grid item md={12} key={index}>
                {index === 0 && (
                  <Grid item md={12}>
                    <Typography sx={{ fontWeight: 600, mb: "10px" }}>
                      Assign To<span style={{ color: "red" }}>*</span>
                    </Typography>
                  </Grid>
                )}

                <Controller
                  name={group}
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      size="small"
                      {...field}
                      multiple
                      disableCloseOnSelect
                      options={getFilteredOptions(index)}
                      getOptionLabel={(option) => option.value}
                      onChange={(event, newValue) => field.onChange(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`Select ${currentAssignedTo || "option"}(s)`}
                          variant="outlined"
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value?.id
                      }
                      renderOption={(props, option, { selected }) => {
                        const { key, ...optionProps } = props;
                        return (
                          <li key={key} {...optionProps}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.value}
                          </li>
                        );
                      }}
                      disabled={isView}
                      PaperComponent={(props) => (
                        <Paper
                          {...props}
                          sx={{
                            marginTop: "-180px",
                            width: "700px",
                            marginLeft: "200px",
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            ))}

            <Grid
              item
              md={12}
              sx={{ display: "flex", gap: "20px", justifyContent: "center" }}
            >
              <Button
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  "&:hover": {
                    borderColor: "#000",
                  },
                  borderColor: "#2a2a2a",
                  color: "#000",
                  marginBottom: "20px",
                  px: "25px",
                }}
                onClick={handleBack}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#2a2a2a",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#000",
                  },
                  marginBottom: "20px",
                  px: "25px",
                }}
                disabled={isView || isLoading}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        )}
      </form>
    </Card>
  );
};

export default AddRule;
