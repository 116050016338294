export const utcFormater = (utc) => {
  let inputDate;
  let formattedDate;

  if (utc) {
    const utcTimestamp = utc;
    const dateInUTC = new Date(utcTimestamp);
    const localTime = dateInUTC.toLocaleDateString("en-GB");
    inputDate = localTime.split(",");

    const [year, month, day] = inputDate[0].split("/");
    formattedDate = `${year}-${month}-${day.padStart(2, "0")}`;
  } else {
    return [];
  }

  return [formattedDate, inputDate];
};

export const MUIDateConverter = (date) => {
  const dateObject = new Date(date);

  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0");
  const day = String(dateObject.getDate()).padStart(2, "0");

  const hours = String(dateObject.getHours()).padStart(2, "0");
  const minutes = String(dateObject.getMinutes()).padStart(2, "0");
  const seconds = String(dateObject.getSeconds()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  const formattedTime = `${hours}:${minutes}:${seconds}`;

  return [formattedDate, formattedTime];
};

const formattedDate = (date) => {
  return new Date(date)
    ?.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .split("/")
    .reverse()
    .join("-");
};
