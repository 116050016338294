import { Autocomplete, Checkbox, TextField, Paper } from "@mui/material";
import { Controller } from "react-hook-form";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useState, useEffect } from "react";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MultiSelectBox = ({
  control,
  isRequired,
  menuList = [],
  arrangeModal = {},
  name,
  inputLabel,
  disabled,
  disableCloseOnSelect = true,
  selectAllOption = false,
}) => {
  const [options, setOptions] = useState(menuList);

  const handleSelectAll = (value, currentValue) => {
    if (value.some((option) => option.id === "select-all")) {
      return currentValue.length === menuList.length
        ? []
        : menuList.map((option) => option.id);
    } else {
      return value.map((option) => option.id);
    }
  };
  useEffect(() => {
    if (selectAllOption) {
      setOptions([{ id: "select-all", value: "Select All" }, ...menuList]);
    } else {
      setOptions(menuList);
    }
  }, [menuList, selectAllOption]);

  return (
    <Controller
      name={name}
      rules={{ required: isRequired ? "required" : false }}
      control={control}
      defaultValue={[]}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          multiple
          size="small"
          id="checkboxes-tags-demo"
          disabled={disabled}
          options={options}
          disableCloseOnSelect={disableCloseOnSelect}
          getOptionLabel={(option) => option?.value}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          renderOption={(props, option, { selected }) => {
            const { key, ...optionProps } = props;
            return (
              <li key={key} {...optionProps}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.value}
              </li>
            );
          }}
          value={field.value.map((id) =>
            options.find((option) => option.id === id)
          )}
          onChange={(_, value) =>
            field.onChange(handleSelectAll(value, field.value))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!error}
              helperText={error ? error.message : null}
              label={inputLabel}
            />
          )}
          PaperComponent={(props) => <Paper {...props} sx={arrangeModal} />}
        />
      )}
    />
  );
};

export default MultiSelectBox;
