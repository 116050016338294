import { Card, Grid, Typography } from "@mui/material";
import Modal from "../../../../components/Modal/Modal";
import { useForm, Controller } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { MUIDateConverter } from "../../../../hooks/helperFunction/dateConverter";
import { axiosFetch } from "../../../../Utlis/AxiosFetch";
import { toast } from "react-toastify";
import styles from "./FreezeProgram.module.css";
import Button from "../../../../components/CustomButton/Button";

const initialState = {
  endDate: null,
};

const EndProgram = ({ isOpen, onClose, client, reLoadList ,EndProgram}) => {

  let end_date ;

  console.log(client , 'client' , EndProgram)

if(EndProgram){
  end_date = client.end_date
}else{
  end_date = client?.programFreeze?.toDate
}

  const form = useForm({
    defaultValues: initialState,
  });
  const { handleSubmit, control, reset } = form;

  const onSubmit = async (formData) => {
    try {

      console.log(formData , 'formData')
      
      if(EndProgram){


        const response = await axiosFetch({
          url: `/FreezeEndBeforeTenureEnd/${client.programFreeze.id}`,
          method: "post",
          requestConfig: formData,
        });
  
        if (response.status === 200) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          reset();
          onClose();
          reLoadList();
        }

      }else{
        const response = await axiosFetch({
          url: `/programEndBeforeTenureEnd/${client?.id}`,
          method: "post",
          requestConfig: formData,
        });
  
        if (response.status === 200) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          reset();
          onClose();
          reLoadList();
        }
      }

    
     
    } catch {}
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <Card elevation={0} sx={{ width: "500px" }}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <Typography
                sx={{
                  fontWeight: 600,
                  color: "red",
                  fontSize: "20px",
                }}
              >
                Choose a date to terminate the {EndProgram ? "Freeze" : "Program" } 
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Typography
                sx={{
                  fontWeight: 600,
                  marginBottom: "10px",
                }}
              >
                End Date
              </Typography>
              <Controller
                name="endDate"
                control={control}
                rules={{ required: "required" }}
                render={({ field, fieldState: { error } }) => (
                  <LocalizationProvider dateAdapter={AdapterDayjs} size="small">
                    <DatePicker
                      sx={{ width: "100%" }}
                      {...field}
                      onChange={(event) => {
                        field.onChange(MUIDateConverter(event.$d)[0]);
                      }}
                      value={field.value && dayjs(field.value)}
                      minDate={dayjs(new Date())}
                      maxDate={ !EndProgram ? dayjs(client.end_date):dayjs(client?.programFreeze?.toDate)}
                      slotProps={{
                        textField: {
                          variant: "outlined",
                          error: !!error,
                          helperText: error?.message,
                        },
                      }}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid
              item
              md={6}
              sx={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
              }}
            >
              <Button inline type={"submit"} className={"session-btn-confirm"}>
                Save
              </Button>
              <Button inline onClick={handleClose} className={"session-btn-confirm"}>
                cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
    </Modal>
  );
};

export default EndProgram;
