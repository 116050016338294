import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Box, Tabs, Tab, Typography, Paper, InputBase } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import PropTypes from "prop-types";

import ThresholdTable from "./ThresholdTable";
import Card from "../../../components/Card/Card";
import { axiosFetch } from "../../../Utlis/AxiosFetch";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Threshold = () => {
  const history = useHistory();
  const [tabValue, setTabValue] = useState(0);
  const isMountedRef = useRef(true);

  const [thresholdList, setThresholdList] = useState();
  const thresholdType = tabValue === 1 ? "trainer" : "dietitian";

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleBack = () => {
    history.push({
      pathname: "/Settings/automation",
      state: {
        tab: 1,
      },
    });
  };

  const handleSearch = async (value) => {
    try {
      const request = await axiosFetch({
        url: `/api/v1/clientAutoThreshold/searchFilter/?search=${value}&thresholdType=${thresholdType}`,
        method: "get",
      });
      setThresholdList(request.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getThresholdList = async () => {
    try {
      const request = await axiosFetch({
        url: `/api/v1/clientAutoThreshold?thresholdType=${thresholdType}`,
        method: "get",
      });
      if (request.status === 200) {
        setThresholdList(request.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getThresholdList();
    return () => {
      isMountedRef.current = false;
    };
  }, [tabValue]);

  return (
    <Card>
      <Typography
        sx={{
          display: "inline-flex",
          alignItems: "center",
          width: "cont",
          gap: "5px",
          fontSize: "18px",
          fontWeight: "600",
          cursor: "pointer",
        }}
        onClick={handleBack}
      >
        <KeyboardBackspaceIcon />
        Threshold
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: "10px" }}>
        <Paper
          component="form"
          sx={{
            p: "0px 4px",
            display: "flex",
            alignItems: "center",
            width: 400,
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search"
            inputProps={{ "aria-label": "search" }}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        </Paper>
      </Box>
      <Box
        sx={{
          minHeight: "100vh",
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            sx={{ "& .MuiTabs-indicator": { backgroundColor: "#000" } }}
            TabIndicatorProps={{
              style: {
                left: `${tabValue === 0 ? 10 : 105}px`, // Adjust the left value dynamically based on tab index
              },
            }}
            value={tabValue}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label={
                <Typography
                  sx={{ textTransform: "capitalize", fontWeight: 600 }}
                >
                  {"Dietitian"}
                </Typography>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <Typography
                  sx={{ textTransform: "capitalize", fontWeight: 600 }}
                >
                  {"Trainer"}
                </Typography>
              }
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>

        <CustomTabPanel value={tabValue} index={0}>
          {thresholdList?.dietitianList && (
            <ThresholdTable
              thresholdList={thresholdList?.dietitianList}
              thresholdType={"dietitian"}
              getThresholdList={getThresholdList}
            />
          )}
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          {thresholdList?.trainerList && (
            <ThresholdTable
              thresholdList={thresholdList?.trainerList}
              thresholdType={"trainer"}
              getThresholdList={getThresholdList}
            />
          )}
        </CustomTabPanel>
      </Box>
      <Box sx={{ width: "800px" }}></Box>
    </Card>
  );
};

export default Threshold;
