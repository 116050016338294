import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ImageIcon from "@mui/icons-material/Image";
import MessageIcon from "@mui/icons-material/Message";

import Button from "../../components/CustomButton/Button";
import moment from "moment";

const useReportActivity = (
  handleClick,
  handleComment,
  handleLiveSessionModal
) => {
  const actvityColumn = [
    {
      field: "clientId",
      headerName: "Account ID",
      width: 200,
      headerClassName: "super-app-theme--header",
      //   renderCell: (params) => {
      //     return "";
      //   },
    },
    {
      field: "client_name",
      headerName: "Client Name",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "clientsProgram",
      headerName: "Program Name",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "nutritionist",
      headerName: "Nutritionist",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "trainer",
      headerName: "Trainer",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "progress",
      headerName: "Progress %",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        const progressValue = params.row?.progress;
        let colorValue;

        if (progressValue === null) {
          return "";
        }

        if (progressValue < 75)
          colorValue = { color: "red", hexa: "#FF00001A" };
        if (progressValue >= 75 && progressValue < 95)
          colorValue = { color: "orange", hexa: "#FFA5001A" };
        if (progressValue >= 95 && progressValue <= 100)
          colorValue = { color: "green", hexa: "#0080001A" };

        return (
          <Box sx={{ position: "relative", display: "inline-flex" }}>
            <Stack sx={{ color: colorValue.color }} direction="row">
              <CircularProgress
                variant="determinate"
                color="inherit"
                value={progressValue}
              />
            </Stack>
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: colorValue.hexa,
                borderRadius: "50%",
              }}
            >
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
              >
                {progressValue}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "consumed_calories",
      headerName: "Consumed Calories",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "no_meals",
      headerName: "No of Meals Pictures Adder",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", gap: "50px" }}>
            <span>
              {params.row?.no_meals === null ? 0 : params.row?.no_meals}
            </span>
            <ImageIcon
              style={{ fontSize: "20px", color: "#A1A1A1", cursor: "pointer" }}
              onClick={(event) => handleClick(event, params)}
            />
          </div>
        );
      },
    },
    {
      field: "water",
      headerName: "Water Intake (Ltr)",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "sleep",
      headerName: "Sleep (Hrs)",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "calories_burnt",
      headerName: "Calories Burnt",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "live_session",
      headerName: "Live Session",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (res) => {
        return (
          <span
            style={{
              cursor: "pointer",
              backgroundColor: "#FF00001A",
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => {
              handleLiveSessionModal(res.row);
            }}
          >
            {res.row.live_session}
          </span>
        );
      },
    },
    {
      field: "steps",
      headerName: "Steps",
      width: 200,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "Date",
      headerName: "Date",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        const activityDate = params.row?.Date !== null && params.row?.Date !== undefined
        ? params.row?.Date
        : "";
        return (
          <Box sx={{ display: 'flex', gap: '50px' }}>
          <Typography
      component="span" 
      sx={{
        fontFamily: 'inherit', 
        fontSize: 'inherit', 
       
      }}
    >
      {activityDate ? moment(activityDate).format('DD-MM-YYYY'):''}
    </Typography>
    </Box>
        );
      },
    },
    {
      field: "comments",
      headerName: "Comments",
      width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", gap: "50px" }}>
            <span>{params.row?.comments}</span>
            <MessageIcon
              style={{ fontSize: "20px", color: "#A1A1A1", cursor: "pointer" }}
              onClick={(event) => handleComment(event, params)}
            />
          </div>
        );
      },
    },
  ];

  return { actvityColumn };
};

export default useReportActivity;
