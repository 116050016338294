import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
} from "@mui/material";

import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import UpdateThresold from "./Modals/UpdateThresold";
import { useEffect, useState } from "react";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import { toast } from "react-toastify";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      border: "1px solid #D0D0D0",
    },
  },
});

const table = {
  header: { fontWeight: "600" },
};

const languages = [
  { id: 1, value: "English" },
  { id: 2, value: "Tamil" },
  { id: 3, value: "Hindi" },
  { id: 4, value: "Telugu" },
  { id: 5, value: "Kannada" },
  { id: 6, value: "Urdu" },
  { id: 7, value: "Malayalam" },
  { id: 8, value: "Bengali" },
  { id: 9, value: "Arabic" },
  { id: 10, value: "Gujarati" },
  { id: 11, value: "Konkani" },
  { id: 12, value: "Marathi" },
  { id: 13, value: "Punjabi" },
];

const ThresholdTable = ({ thresholdList, thresholdType, getThresholdList }) => {
  const classes = useStyles();

  const [isOPen, setIsOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [dropDownValues, setDropDownValues] = useState({});
  const [sortOrder, setSortOrder] = useState({
    sortBy: "name",
    type: "asc",
  });
  const [sortedList, setSortedList] = useState(thresholdList);
  const handleSort = (value) => {
    const list = [...sortedList];

    if (sortOrder.type === "asc") {
      list.sort((a, b) => {
        if (typeof a[value] === "number" && typeof b[value] === "number") {
          return a[value] - b[value];
        } else {
          return String(a[value]).localeCompare(String(b[value]));
        }
      });
      setSortOrder({
        sortBy: value,
        type: "desc",
      });
    } else {
      list.sort((a, b) => {
        if (typeof a[value] === "number" && typeof b[value] === "number") {
          return b[value] - a[value];
        } else {
          return String(b[value]).localeCompare(String(a[value]));
        }
      });
      setSortOrder({
        sortBy: value,
        type: "asc",
      });
    }

    setSortedList(list);
  };

  const handleEdit = (value) => {
    setCurrentRow(value);
    setIsOpen(true);
  };

  const handleThreshold = async (formdata, reset) => {
    try {
      setIsLoading(true);
      const findType = {
        trainer: "trainerId",
        dietitian: "dietitianId",
      };
      const object = {
        threshold: +formdata.thresold,
        dailyThreshold: +formdata.dailyThreshold,
        [findType[thresholdType]]: currentRow.id,
      };
      const request = await axiosFetch({
        url: `/api/v1/clientAutoThreshold?thresholdType=${thresholdType}`,
        method: "put",
        requestConfig: object,
      });

      if (request.status === 200) {
        toast.success(request.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        reset();
        setIsOpen(false);
        getThresholdList();
        setIsLoading(false);
      } else {
        toast.error(request.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(async () => {
    const request = await axiosFetch({
      url: `api/v1/dropdownList`,
      method: "get",
    });

    if (request.status === 200) {
      setDropDownValues(request.data?.dropDownList);
    }
  }, []);

  useEffect(() => {
    handleSort("name");
  }, []);

  useEffect(() => {
    setSortedList(thresholdList);
  }, [thresholdList]);

  const getLanguageValues = (languageIds) => {
    return languages
      .filter((lang) => languageIds?.includes(lang.id))
      .map((lang) => lang.value)
      .join(", ");
  };

  return (
    <Box sx={{ mt: "50px", width: "100%" }}>
      <Box display="flex" justifyContent="center" mb={2}>
        <Typography variant="body1" sx={{ marginRight: 2, display: "flex" }}>
          <Box
            sx={{
              width: 20,
              height: 20,
              backgroundColor: "#fa5252",

              marginRight: 1,
            }}
          />
          More than 100% (Red)
        </Typography>
        <Typography variant="body1" sx={{ marginRight: 2, display: "flex" }}>
          <Box
            sx={{
              width: 20,
              height: 20,
              backgroundColor: "#fab005",

              marginRight: 1,
            }}
          />
          80% - 100% (Yellow)
        </Typography>
        <Typography variant="body1" sx={{ display: "flex" }}>
          <Box
            sx={{
              width: 20,
              height: 20,
              backgroundColor: "#40c057",
              display: "block",
              marginRight: 1,
            }}
          />
          Below 80% (Green)
        </Typography>
      </Box>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead sx={{ backgroundColor: "#F4F4F4" }}>
            <TableRow>
              <TableCell
                align="center"
                sx={{
                  ...table.header,
                  cursor: "pointer",
                  backgroundColor: sortOrder.sortBy === "name" ? "#dee2e6" : "",
                }}
                onClick={() => handleSort("name")}
              >
                Coach Name
                {sortOrder.type === "asc" ? "▲" : "▼"}
              </TableCell>
              <TableCell align="center" sx={table.header}>
                Language
              </TableCell>
              <TableCell align="center" sx={table.header}>
                Shift Timing
              </TableCell>
              <TableCell align="center" sx={table.header}>
                Type
              </TableCell>
              <TableCell align="center" sx={{ ...table.header }}>
                Medical Condition
              </TableCell>
              {/* {thresholdType === "dietitian" && ( */}

              <TableCell align="center" sx={table.header}>
                Preferred Cuisine
              </TableCell>

              {/* )} */}
              <TableCell
                align="center"
                sx={{
                  ...table.header,
                  cursor: "pointer",
                  backgroundColor:
                    sortOrder.sortBy === "active_client_count" ? "#dee2e6" : "",
                }}
                onClick={() => handleSort("active_client_count")}
              >
                Active Count {sortOrder.type === "asc" ? "▲" : "▼"}
              </TableCell>
              <TableCell align="center" sx={table.header}>
                Threshold
              </TableCell>

              <TableCell align="center" sx={table.header}>
                Balance
              </TableCell>

              <TableCell
                align="center"
                sx={{
                  ...table.header,
                  cursor: "pointer",
                  backgroundColor:
                    sortOrder.sortBy === "daily_active_client_count"
                      ? "#dee2e6"
                      : "",
                }}
                onClick={() => handleSort("daily_active_client_count")}
              >
                Active Daily Count {sortOrder.type === "asc" ? "▲" : "▼"}
              </TableCell>
              <TableCell align="center" sx={table.header}>
                Daily Threshold
              </TableCell>
              <TableCell align="center" sx={table.header}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedList?.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="left">{row?.name}</TableCell>
                <TableCell align="left">
                  {getLanguageValues(row?.Language)}
                </TableCell>

                <TableCell align="left" sx={{ width: "130px" }}>
                  {row.shiftTimings}
                </TableCell>
                <TableCell align="left">{row.coachCategory}</TableCell>
                <TableCell align="left" sx={{ width: "200px" }}>
                  {row.medical_condition?.join(", ")}
                </TableCell>

                <TableCell align="left" sx={{ width: "200px" }}>
                  {row.preferred_cuisine?.join(", ")}
                </TableCell>

                <TableCell
                  align="right"
                  sx={{
                    backgroundColor:
                      row.active_client_count / row.threshold > 1
                        ? "#fa5252"
                        : row.active_client_count / row.threshold >= 0.8
                        ? "#fab005"
                        : "#40c057",
                    width: "200px",
                  }}
                >
                  {row.active_client_count}
                </TableCell>
                <TableCell align="right">{row.threshold}</TableCell>
                {/* <TableCell align="right">{row.dailyThreshold}</TableCell> */}
                <TableCell align="right">
                  {Math.max(0, row.threshold - row.active_client_count)}
                </TableCell>

                <TableCell
                  align="right"
                  sx={{
                    backgroundColor:
                      row.daily_active_client_count / row.dailyThreshold > 1
                        ? "#fa5252"
                        : row.daily_active_client_count / row.dailyThreshold >=
                          0.8
                        ? "#fab005"
                        : "#40c057",
                    width: "185px",
                  }}
                >
                  {row.daily_active_client_count}
                </TableCell>
                <TableCell sx={{ width: "150px" }} align="right">
                  {row.dailyThreshold}
                </TableCell>
                <TableCell align="right">
                  <EditIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleEdit(row)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <UpdateThresold
        isOpen={isOPen}
        onClose={() => setIsOpen(false)}
        handleThreshold={handleThreshold}
        isLoading={isLoading}
        currentRow={currentRow}
      />
    </Box>
  );
};

export default ThresholdTable;
