import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { LeadDetailsPopup } from "./Index";
import { axiosFetch } from "../../Utlis/AxiosFetch";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, TextField } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import moment from "moment";

const UnAssignedPayments = () => {
  const history = useHistory();
  const [paymentList, setPaymentList] = useState([]);
  const [kywd, setKywd] = useState("");

  function dateConverter(str) {
    var date = new Date(str);
    var mnth = ("0" + (date.getMonth() + 1)).slice(-2);
    var day = ("0" + date.getDate()).slice(-2);
    var hours = ("0" + date.getHours()).slice(-2);
    var minutes = ("0" + date.getMinutes()).slice(-2);
    var seconds = ("0" + date.getSeconds()).slice(-2);
    var year = date.getFullYear();
    return `${year}-${mnth}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const getPaymentList = () => {
    axiosFetch({
      url: "/getUnallocatedPayments",
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          setPaymentList(res.data);
        } else if (res.status === 401) {
          history.push("/");
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
    // .catch(err =>{
    //   toast.error(err?.response?.data?.message, { position: toast.POSITION.TOP_CENTER });
    // })
  };
  useEffect(() => {
    getPaymentList();
  }, []);

  const returnLeadDetails = (leadObj) => {
    if (leadObj !== null) {
      if (leadObj.id !== null && leadObj.name !== null) {
        return `${leadObj.id},${leadObj.name}`;
      }
    }
  };

  const allocateLead = (id) => {
    let obj = paymentList.filter((payment) => payment.id === id)[0];
    history.push({ pathname: "/allocateLead", state: { paymentList: obj } });
  };

  const handleClear = () => {
    setKywd("");
  };

  const handleSearch = () => {};

  const handleChangeKeyword = (e) => {
    setKywd(e.target.value);
  };

  return (
    <div style={{ margin: "29px" }}>
      {/* <br/>

            <Card sx={{ minWidth: 275 }}>
                <br/> 
                <CardContent>
                  <input type="text" placeholder="Search Keywords" style={{width : "12%", height : "55px"}} value={kywd} onChange={handleChangeKeyword}/>
                </CardContent>
                <CardActions>
                  <Button size="small" variant="contained" onClick={()=>handleClear()}>Clear</Button>
                  <Button size="small" variant="contained" onClick={()=>handleSearch()}>Search</Button>
                </CardActions>
            </Card> */}
      <br />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Payment Date and Time</strong>
              </TableCell>
              <TableCell>
                <strong>Name</strong>
              </TableCell>
              <TableCell>
                <strong>Email ID</strong>
              </TableCell>
              <TableCell>
                <strong>Source</strong>
              </TableCell>
              <TableCell>
                <strong>Contact Number</strong>
              </TableCell>
              <TableCell>
                <strong>Payment Status</strong>
              </TableCell>
              <TableCell>
                <strong>Program Name</strong>
              </TableCell>
              <TableCell>
                <strong>Lead Details</strong>
              </TableCell>
              <TableCell>
                <strong>Reallocated Status</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentList.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
              <TableCell component="th" scope="row">
  {moment(dateConverter(row?.payment_date)).format("DD/MM/YYYY HH:MM:SS")}
</TableCell>
                <TableCell>{!!row.name ? row.name : "-"}</TableCell>
                <TableCell>{!!row.email_id ? row.email_id : "-"}</TableCell>
                <TableCell>{!!row.source ? row.source : "-"}</TableCell>
                <TableCell>
                  {!!row.contact_number ? row.contact_number : "-"}
                </TableCell>
                <TableCell>
                  {!!row.payment_status ? row.payment_status : "-"}
                </TableCell>
                <TableCell>
                  {!!row.program_detail ? row.program_detail?.name : "-"}
                </TableCell>
                <TableCell>
                  {!!row.lead_detail ? (
                    returnLeadDetails(row.lead_detail)
                  ) : (
                    <Button
                      variant="outlined"
                      onClick={() => allocateLead(row.id)}
                      startIcon={<AddIcon />}
                    >
                      Map Lead
                    </Button>
                  )}
                </TableCell>
                <TableCell>{row.is_reallocated ? "Yes" : "No"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default UnAssignedPayments;
