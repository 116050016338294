import * as yup from "yup";

// const phoneRegExp = /^[+]\d{2}?\d{10}$/;
const phoneRegExp = /^\+[1-9]{1}[0-9]{3,14}$/;
const nameRegex = /^[a-zA-Z][a-zA-Z. ]*$/;
const heightRegex = /^[0-9.]*$/;
const workHoursRegex = /^[\d-]*$/;
const ageRegExp = /^([0-1]?[0-9]|20)$/;

export const addLeadSchema = yup.object().shape({
  name: yup
    .string()
    .required("Enter the name")
    .matches(nameRegex, "Enter a valid name")
    .min(3, "Name should be minimum 3 letters")
    .max(50, "Name cannot be more than 50"),
  //name:new StringSchema('Enter valid name').required('Enter dd name'),
  email_id: yup.string().email("Invalid email").required("Email is required"),
  contact_number: yup
    .string()
    .matches(phoneRegExp, "Enter a valid Mobile Number")
    .required("Contact number is required"),

  account_name: yup
    .string()
    .matches(/^[@][_a-z][_a-z\d]*/, "Enter a valid account name,start with @")
    .min(4, "Account name must have minimum 3 letters"),
  // .required("Account name is required"),

  age: yup
    .number()
    // .required("Please supply your age")
    .min(15, "Minimum age is 15")
    .max(85, "Maximum age is 85")
    .nullable(),
  // dob: yup
  //   .date()
  //   // .required("Dob is required")
  //   .min(new Date(new Date() - 2695197826716), `Age should be Maximum 85`)
  //   .max(new Date(new Date() - 504600000000), `Minimum age is 15`),
  gender: yup.string().nullable(),
  // .required("Mention gender"),
  height: yup
    .number()
    .typeError("Height must be in digits")
    .required("Height is required")
    .max(213, "Enter a valid Height")
    .min(91, "Enter a valid height"),
  current_weight: yup
    .number()
    .typeError("Weight must be in digits")
    .required("Current weight is required")
    .min(30, "Enter a valid weight")
    .max(250, "Enter a valid weight"),
  // city: yup
  //   .string()
  //   .matches(nameRegex, "Enter a valid city name")
  //   .min(3, "Enter a valid city"),
  // state: yup
  //   .string()
  //   .matches(nameRegex, "Enter a valid State name")
  //   .min(3, "Enter a valid State"),
  // country: yup
  //   .string()
  //   .matches(nameRegex, "Enter a valid Country name")
  //   .min(3, "Enter a valid Country"),
  // pincode: yup
  //   .string()
  //   .matches(heightRegex, "Enter a valid pincode")
  //   .min(4, "Pincode must be minimum 4 digits")
  //   .max(6, "Pincode must be maximum 6 digits"),
  // occupation: yup.string()
  //             .matches(nameRegex, "Enter a valid profession"),
  // .required("Profession required"),
  // emergency_contact: yup
  //   .string()
  //   .matches(phoneRegExp, "Enter a valid Mobile Number")
  //   .notOneOf([yup.ref('contact_number'),null],'Enter a different contact number'),
  current_medical_condition: yup.array(yup.string()).nullable(),
  // .min(1, "Select current medical condition"),
  // lead_status: yup.string().required("Select a lead status"),
  next_followup_date: yup
    .date("Enter a valid date")
    // .min(
    //   new Date(
    //     new Date().getFullYear(),
    //     new Date().getMonth(),
    //     new Date().getDate()
    //   ),
    //   "Cannot select previous date"
    // )
    .nullable(),
  // vmax_lead_stage_id : yup.string().required('Please select something'),
  // vmax_lead_disposition_id : yup.string().required('Please select something'),
  // vmax_lead_sub_disposition_id : yup.string().required("Please select something"),
  // vmax_lead_sub_not_interested_id : yup.string().required('Please select something'),

  preferred_language: yup.string().required("Select a preferred language"),
});

export const updateLeadSchema = yup.object().shape({
  name: yup
    .string()
    .required("Enter the name")
    .matches(nameRegex, "Enter a valid name")
    .min(3, "Name should be minimum 3 letters")
    .max(50, "Name cannot be more than 50"),
  //name:new StringSchema('Enter valid name').required('Enter dd name'),
  email_id: yup
    .string()
    .email("Invalid email")
    .required("Email is required")
    .nullable(),
  contact_number: yup
    .string()
    .matches(phoneRegExp, "Enter a valid Mobile Number")
    .required("Contact number is required"),

  account_name: yup
    .string()
    .matches(/^[@][_a-z][_a-z\d]*/, "Enter a valid account name,start with @")
    .min(4, "Account name must have minimum 3 letters")
    .nullable(),
  age: yup
    .number()
    // .required("Please supply your age")
    .min(15, "Minimum age is 15")
    .max(85, "Maximum age is 85")
    .nullable(),
  // dob: yup
  //   .date().typeError('Enter a valid date')

  //   .min(new Date(new Date() - 2695197826716), `Age should be Maximum 85`)
  //   .max(new Date(new Date() - 504600000000), `Minimum age is 15`),
  gender: yup.string().nullable(),
  // .required("Mention gender"),

  height_unit: yup.number().required("Unit is required").nullable(),
  // vmax_lead_stage_id : yup.string().required('Please select something'),
  // vmax_lead_disposition_id : yup.string().required('Please select something'),
  // vmax_lead_sub_disposition_id : yup.string().required("Please select something"),
  // vmax_lead_sub_not_interested_id : yup.string().required('Please select something'),

  height: yup.mixed().when("height_unit", {
    is: 0,
    then: yup
      .number()
      .typeError("must be number in cms")
      .max(213, "Enter a valid Height")
      .min(91, "Enter a valid height")
      .required("Please enter height"),
    // otherwise: yup.string().matches(/^((\d+)').?(\d+)(?:")$/, "enter valid height like 5'5\"").required('Please enter height')
    otherwise: yup
      .string()
      .matches(
        /^(?!$|.*\'[^\x22]+$)(?:([3-9]+)\').?(?:([0-9]+)\x22?)?$/,
        "enter valid height like 5'5\", should be more than 3'"
      )
      .required("Please enter height"),
  }),

  // contact_number: Joi.when('signup_type', { is: 1, then: Joi.string().pattern(/^[+0-9]+$/).required() })

  // .typeError("Height must be in digits")
  // .required("Height is required")
  // .max(213, "Enter a valid Height")
  // .min(91, "Enter a valid height"),

  weight_unit: yup.number().required("Unit is required").nullable(),

  // current_weight: yup
  //   .number()
  //   .typeError("Weight must be in digits")
  //   .required("Current weight is required")
  //   .min(30,'Enter a valid weight')
  //   .max(250,'Enter a valid weight'),

  current_weight: yup.number().when("weight_unit", {
    is: 0,
    then: yup
      .number()
      .max(250, "Enter a valid weight")
      .min(30, "Enter min a valid weight")
      .required("Please enter weight"),
    otherwise: yup
      .number()
      .max(550, "Enter a valid weight")
      .min(65, "Enter min a valid weight")
      .required("Please enter weight"),
  }),

  address: yup.string().nullable(),
  // city: yup
  //   .string()
  //   .matches(nameRegex, "Enter a valid city name")
  //   .min(3, "Enter a valid city")
  //   .nullable(),
  // state: yup
  //   .string()
  //   .matches(nameRegex, "Enter a valid State name")
  //   .min(3, "Enter a valid State")
  //   .nullable(),
  // country: yup
  //   .string()
  //   .matches(nameRegex, "Enter a valid Country name")
  //   .min(3, "Enter a valid Country")
  //   .nullable(),
  // pincode: yup
  //   .string()
  //   .matches(heightRegex, "Enter a valid pincode")
  //   .min(4, "Pincode must be minimum 4 digits")
  //   .max(6, "Pincode must be maximum 6 digits")
  //   .nullable(),
  // mother_tongue:yup.string().nullable(),
  // occupation: yup.string()
  // .matches(nameRegex, "Enter a valid profession"),
  // .required("Profession required"),
  // work_hours:yup.string().nullable() ,
  // family_details:yup.string().nullable(),
  // emergency_contact: yup
  //   .string()
  //   .matches(phoneRegExp, "Enter a valid Mobile Number").nullable()
  //   .notOneOf([yup.ref('contact_number'),null],'Enter a different contact number'),
  current_medical_condition: yup.array(yup.string()).nullable(),
  // .min(1, "Select current medical condition"),
  // lead_status: yup.string().required("Select a lead status").nullable(),

  //InterestedIn:yup.string().required("Enter interests").nullable(),
  // call_status:yup.string().required('Select a call status').nullable(),
  next_followup_date: yup
    .date("Enter a valid date")
    // .min(
    //   new Date(
    //     new Date().getFullYear(),
    //     new Date().getMonth(),
    //     new Date().getDate()
    //   ),
    //   "Cannot select previous date"
    // )
    .nullable(),
  source: yup.string().nullable(),
  assigned_to: yup.string().nullable(),
});

export const paymentDetailSchema = yup.object().shape({
  Amount: yup
    .string()
    .matches(/^[0-9.]*$/, "Enter a valid amount")
    .required("Enter the amount"),
  payment_type: yup.string().required("Select payment type"),
  PaymentDate: yup
    .date("Enter a valid date")
    .required("Enter the date")
    .max("01-01-9999", "Enter a valid date"),
});

export const addUserSchema = yup.object().shape({
  firstName: yup.string().required("FirstName is required"),
  // reportingManagerDetail: yup.number(),
  languageDisplayValue: yup
    .array()
    .of(yup.string().required("Each language selection is required")) // Each language in the array should be a string and required
    .min(1, "At least one language must be selected") // Ensures the array has at least one item
    .required("Language is required"), // Ensures the array itself is required

  proficiencyDisplayValue: yup.string().required("Proficiency  is required"),
  departmentDisplayValue: yup.string().required("Department is required"),
  Email: yup.string().email("Invalid email").nullable(),
  Username: yup.string().required("Username is required"),
  Mobile_number: yup
    .string()
    .matches(phoneRegExp, "Enter a valid Mobile Number")
    .nullable(),
  //Password:yup.string().required('Enter a password'),
  // roles:yup.string().required('Select a role')
  // personName:yup.array().required('Select a role')
});
