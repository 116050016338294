import Modal from "../../../../components/Modal/Modal";
import TextBox from "../../../../components/TextFields/TextBox";
import { useForm } from "react-hook-form";
import { Box, Grid, Typography, Button } from "@mui/material";
import { useEffect } from "react";

const initialValue = { thresold: "", dailyThreshold: "" };

const UpdateThresold = ({
  isOpen,
  onClose,
  handleThreshold,
  isLoading,
  currentRow,
}) => {
  const form = useForm({
    defaultValues: initialValue,
  });

  const { control, handleSubmit, reset } = form;

  const onSubmit = async (formdata) => {
    handleThreshold(formdata, reset);
  };

  useEffect(() => {
    if (currentRow?.threshold !== undefined) {
      reset({
        thresold: currentRow.threshold,
        dailyThreshold: currentRow.dailyThreshold,
      });
    }
  }, [currentRow?.threshold, currentRow.dailyThreshold, reset]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ width: "300px" }}>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <Typography sx={{ fontWeight: 600, marginBottom: "10px" }}>
                Total Threshold
              </Typography>
              <TextBox
                control={control}
                name={"thresold"}
                type="number"
                isRequired={{
                  required: "required",
                }}
              />
            </Grid>
            <Grid item md={12}>
              <Typography sx={{ fontWeight: 600, marginBottom: "10px" }}>
                Daily Thresold
              </Typography>
              <TextBox
                control={control}
                name={"dailyThreshold"}
                type="number"
                isRequired={{
                  required: "required",
                }}
              />
            </Grid>

            <Grid
              item
              md={12}
              sx={{ display: "flex", gap: "20px", justifyContent: "flex-end" }}
            >
              <Button
                type="button"
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  "&:hover": {
                    borderColor: "#000",
                  },
                  borderColor: "#2a2a2a",
                  color: "#000",
                  marginBottom: "20px",
                  px: "25px",
                }}
                disabled={isLoading}
                onClick={() => onClose()}
              >
                close
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#2a2a2a",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#000",
                  },
                  marginBottom: "20px",
                  px: "25px",
                }}
                disabled={isLoading}
              >
                save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Modal>
  );
};

export default UpdateThresold;
