import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import { MUIDateConverter } from "../../hooks/helperFunction/dateConverter";

const DatePickerBox = ({ name, control, isRequired, disabled }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: isRequired ? "required" : false }}
      render={({ field, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs} size="small">
          <DatePicker
            sx={{ width: "100%" }}
            format="DD/MM/YYYY"
            {...field}
            onChange={(event) => {
              field.onChange(MUIDateConverter(event.$d)[0]);
            }}
            value={field.value && dayjs(field.value)}
            minDate={dayjs(new Date())}
            disabled={disabled}
            slotProps={{
              textField: {
                variant: "outlined",
                error: !!error,
                helperText: error?.message,
              },
            }}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default DatePickerBox;
