import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import Typography from "@mui/material/Typography";

export default function TemplateTable(props) {
  // const [rows, setRow] = useState([]);

  const columns = [
    {
      field: "id",
      headerName: "Id",
      // flex: 1,
      width: 300,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "template_name",
      headerName: "Template name",
      flex: 1,
      //   width: 90,
      headerClassName: "super-app-theme--header",
      
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 1,
      //   width: 90,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      //   width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        const createdDate = params.row?.createdAt !== null && params.row?.createdAt !== undefined
        ? params.row?.createdAt
        : "";
        return (
          <Box sx={{ display: 'flex', gap: '50px' }}>
          <Typography
      component="span" 
      sx={{
        fontFamily: 'inherit', 
        fontSize: 'inherit', 
       
      }}
    >
      {createdDate ? moment(createdDate).format('DD-MM-YYYY'):''}
    </Typography>
    </Box>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      //   width: 200,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <>
            <button
              className="btn btn-default btn-xs no-focus"
              title="Edit"
              onClick={() => editTemplate(params.row)}
            >
              <i className="fas fa-pencil" aria-hidden="true"></i>
            </button>
            <button
              className="btn btn-default btn-xs no-focus"
              title="View"
              onClick={() => openWorkoutPlanModal(params.row)}
            >
              <i className="fas fa-eye" aria-hidden="true"></i>
            </button>
            <button
              className="btn btn-default btn-xs no-focus"
              title="Delete"
              onClick={() => deleteTemplate(params.row)}
            >
              <i className="fas fa-trash" aria-hidden="true"></i>
            </button>
          </>
        );
      },
    },
  ];

  const editTemplate = async (data) => {
    props.editTemplate(data);
  };

  const openWorkoutPlanModal = (data) => {
    props.openWorkoutPlanModal(data.id);
  };

  const deleteTemplate = async (data) => {
    props.deleteTemplate(data.id);
  };

  return (
    <Box
      sx={{
        padding: "30px",
        ".MuiDataGrid-row:nth-child(odd)": {
          backgroundColor: "#DCD6D0",
        },
        ".MuiDataGrid-columnHeaderTitle": {
          fontWeight: "bold !important",
          overflow: "visible !important",
        },
      }}
    >
      {props.templateList && props.templateList.length > 0 && (
        <DataGrid
          rows={props.templateList}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[5]}
          checkboxSelection
          disableRowSelectionOnClick
          // getRowClassName={(params) =>
          //   params.rowIndex % 2 === 0 ? "even-row" : "odd-row"
          // }
        />
      )}
    </Box>
  );
}
