import { useContext, useEffect, useReducer, useState } from "react";
import { axiosFetch } from "../../../Utlis/AxiosFetch";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import ChecklistIcon from "@mui/icons-material/Checklist";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import stateContext from "../../../context/StateContext";
import Button from "../../../components/CustomButton/Button";
import DeleteModal from "../modals/DeleteModal";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Popupmodel from "../../Leads/Popupmodel";
import { Grid, Box, Typography } from "@mui/material";

const initialState = {
  dietitianList: [],
  trainerList: [],
  assignList: [],
  selectedTrainer: "select",
  selectedDiettian: "select",
  selectedAssign: "select",
  isDisable: false,
  isDelete: false,
  globalSearch: "",
  isNewLead: false,
  userId: JSON.parse(window.localStorage.getItem("operator"))?.id,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "ListCategory": {
      return {
        ...state,
        dietitianList: action.payload.dietitianList,
        trainerList: action.payload.trainerList,
        assignList: action.payload.assignList,
      };
    }

    case "selectedAssign": {
      return {
        ...state,
        selectedAssign: action.payload,
      };
    }

    case "selectedDiettian": {
      return {
        ...state,
        selectedDiettian: action.payload,
      };
    }
    case "selectedTrainer": {
      return {
        ...state,
        selectedTrainer: action.payload,
      };
    }
    case "selectedAssign": {
      return {
        ...state,
        selectedAssign: action.payload,
      };
    }
    case "globalSearch": {
      return {
        ...state,
        globalSearch: action.payload,
      };
    }

    case "isDisable": {
      return {
        ...state,
        isDisable: action.payload,
      };
    }

    case "isNewLead": {
      return {
        ...state,
        isNewLead: action.payload,
      };
    }

    case "closeModel": {
      return {
        ...state,
        isDelete: action.payload,
        isNewLead: false,
      };
    }

    default:
      throw new Error("Unknown action");
  }
};

const Header = ({ selectedRow, deleteList, saveColumList, onDispatch }) => {
  const [
    {
      dietitianList,
      trainerList,
      assignList,
      selectedDiettian,
      selectedTrainer,
      selectedAssign,
      isDelete,
      userId,
      globalSearch,
      isDisable,
      isNewLead,
    },
    dispatch,
  ] = useReducer(reducer, initialState);

  const location = useLocation();
  const pathName = location.pathname === "/Leads/listLeads" ? "Lead" : "Client";

  const contextState = useContext(stateContext);
  const { featureAccessible } = contextState.state;
  const role = JSON.parse(window.localStorage.getItem("operator"))?.Role;

  // console.log(role, "terstt");

  const isAccess =
    contextState.state.featureAccessible.find((fe) => {
      return fe.feature_id === 1 && fe.sub_feature_id === 4 && fe.is_view;
    }) !== undefined;

  // Client and Lead Assign get list function

  const getDietAndTrainerList = async () => {
    const dietitianList = await axiosFetch({
      url: "/getDietitianList",
      method: "get",
    }).then((res) => res.data);

    const trainerList = await axiosFetch({
      url: "/getTrainerList",
      method: "get",
    }).then((res) => res.data);

    const assignList = await axiosFetch({
      url: "/getSalesAssignUsers",
      method: "get",
    }).then((res) => res.data);

    // Sorting dietitianList based on firstName
    dietitianList.sort((a, b) => a.firstName.localeCompare(b.firstName));

    // Sorting trainerList based on firstName
    trainerList.sort((a, b) => a.firstName.localeCompare(b.firstName));

    // Sorting assignList based on firstName (assuming firstName property exists)
    assignList.sort((a, b) => a.firstName.localeCompare(b.firstName));

    dispatch({
      type: "ListCategory",
      payload: { trainerList, dietitianList, assignList },
    });
  };

  // Assign Client user handler function

  const handleAssignedClientUser = async () => {
    const message =
      role === 1 || role === 24
        ? "Dietitian and Trainer List"
        : role === 28
        ? "Dietitian List"
        : role === 30 && "Trainer List";

    if (!selectedRow) {
      toast.error(`Please select client List`, {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    } else if (selectedDiettian === "select" && selectedTrainer === "select") {
      toast.error(`Please select the ${message}`, {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    let trainerResponse;
    let dietitianResponse;

    if (selectedTrainer !== "select") {
      trainerResponse = await axiosFetch({
        url: `updateTrainerAssignment/${selectedTrainer}`,
        method: "put",
        requestConfig: { ids: selectedRow },
      });
    }

    if (selectedDiettian !== "select") {
      dietitianResponse = await axiosFetch({
        url: `updateDietitianAssignment/${selectedDiettian}`,
        method: "put",
        requestConfig: { ids: selectedRow },
      });
    }

    if (trainerResponse?.status === 200 || dietitianResponse.status === 200) {
      dispatch({ type: "selectedDiettian", payload: "select" });
      dispatch({ type: "selectedTrainer", payload: "select" });
      toast.success("Allocation done successfully", {
        position: toast.POSITION.TOP_CENTER,
      });
      onDispatch({ type: "reloadTable", payload: true });
    }
  };

  // Assign Lead user handler function

  const handleAssignedLeadUser = async () => {
    if (!isAccess) {
      toast.error("You don't have permission to select the Assign List", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    } else if (!isDisable) {
      toast.error("Please select the Assign List", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    } else if (selectedAssign === "select") {
      toast.error("Please select the Assign List", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    const assignId = assignList.filter(
      (item) => item.firstName === selectedAssign
    )[0].id;

    let dataObj = {
      assignTo: assignId,
      ids: selectedRow,
    };
    let request;

    if (selectedAssign) {
      request = await axiosFetch({
        url: `updateLeadsAssignment`,
        method: "post",
        requestConfig: dataObj,
      });
    }

    if (request.status === 200) {
      toast.success("Leads assigned successfully", {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch({ type: "selectedAssign", payload: "select" });
      onDispatch({ type: "reloadTable", payload: true });
    }
  };

  // Global search handler function

  const handleSearch = () => {
    onDispatch({ type: "globalSearchedValue", payload: globalSearch });
  };

  // Global search Clear function

  const handleClear = () => {
    dispatch({ type: "globalSearch", payload: "" });
    onDispatch({ type: "globalSearchedValue", payload: "" });
    onDispatch({ type: "reloadTable", payload: true });
    localStorage.removeItem("testSearch");
    localStorage.removeItem("ClientLeadGlobalSearch");
  };

  // handle delete popup show or open modal

  const handleDeleteModal = () => {
    if (deleteList.length > 0) {
      dispatch({ type: "closeModel", payload: true });
    } else {
      toast.warning(`Please select the ${pathName}`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  // handle Delete Client and Lead

  const handleDelete = async () => {
    const request = await axiosFetch({
      url: `/deleteManyLeads/${deleteList.join()}`,
      method: "delete",
    });

    if (request.status === 200) {
      toast.success(`${pathName} Deleted successfully!`, {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch({ type: "closeModel", payload: false });
      onDispatch({ type: "reloadTable", payload: true });
    } else {
      toast.error(request.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  // Handle save client and lead colum

  const handleSaveColumns = async () => {
    try {
      // if (Object.keys(saveColumList).length > 0) {
      const request = await axiosFetch({
        url: `postListView?userId=${userId}&screen=${
          pathName === "Lead" ? 1 : 2
        }`,
        method: "post",
        requestConfig: saveColumList,
      });

      if (request.status === 200) {
        toast.success(`Table columns saved successfully`, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
      // }
    } catch (err) {
      console.log(err);
    }
  };

  // Assign get list inital call

  useEffect(() => {
    getDietAndTrainerList();
  }, []);

  // Restore Global Search value

  useEffect(() => {
    const isGlobalSearchValue = localStorage.getItem("ClientLeadGlobalSearch");
    let isRestore = localStorage.getItem("testSearch");

    if (isGlobalSearchValue && isRestore) {
      dispatch({ type: "globalSearch", payload: isGlobalSearchValue });
      onDispatch({ type: "globalSearchedValue", payload: isGlobalSearchValue });
    }
  }, []);

  // row is unselected help to set disable

  useEffect(() => {
    if (selectedRow) {
      dispatch({ type: "isDisable", payload: true });
    } else {
      dispatch({ type: "isDisable", payload: false });
      dispatch({ type: "selectedDiettian", payload: "select" });
      dispatch({ type: "selectedTrainer", payload: "select" });
    }
  }, [selectedRow]);

  /* 
   className="client-header sub-margin"  main div
    className="client-row "  next div
  */

  return (
    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
      {pathName === "Lead" && (
        <Grid
          item
          xl={4}
          md={4}
          sm={6}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <Box>
            <FormControl style={{ width: "210px" }} size="small">
              <Select
                labelId="demo-simple-select-label"
                id="demo-select-small"
                onChange={(e) =>
                  dispatch({
                    type: "selectedAssign",
                    payload: e.target.value,
                  })
                }
                value={selectedAssign}
                disabled={isAccess && isDisable ? false : true}
              >
                <MenuItem value={"select"} disabled>
                  Assign To
                </MenuItem>
                {assignList.map((item) => (
                  <MenuItem key={item.id} value={item.firstName}>
                    {item.firstName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <Button inline type={"button"} onClick={handleAssignedLeadUser}>
              Submit
            </Button>
          </Box>
        </Grid>
      )}

      {pathName !== "Lead" && (
        <Grid
          item
          xl={4}
          md={4}
          sm={12}
          sx={{ display: "flex", alignItems: "center", gap: "15px" }}
        >
          {role !== 29 && role !== 31 && (
            <>
              {(role === 28 || role === 1 || role === 24) && (
                <Box>
                  {/* <Typography>Dietitian List</Typography> */}
                  <FormControl style={{ width: "210px" }} size="small">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-select-small"
                      onChange={(e) =>
                        dispatch({
                          type: "selectedDiettian",
                          payload: e.target.value,
                        })
                      }
                      value={selectedDiettian}
                      disabled={!isDisable}
                    >
                      <MenuItem value={"select"} disabled>
                        Dietitian List
                      </MenuItem>
                      {dietitianList.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.firstName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}
              {(role === 30 || role === 1 || role === 24) && (
                <Box>
                  {/* <Typography>Trainer List</Typography> */}
                  <FormControl style={{ width: "210px" }} size="small">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-select-small"
                      onChange={(e) =>
                        dispatch({
                          type: "selectedTrainer",
                          payload: e.target.value,
                        })
                      }
                      value={selectedTrainer}
                      disabled={!isDisable}
                    >
                      <MenuItem value={"select"} disabled>
                        Trainer List
                      </MenuItem>
                      {trainerList.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.firstName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              )}

              <Box>
                {/* <Typography>&nbsp;</Typography> */}
                <Button
                  inline
                  type={"button"}
                  onClick={handleAssignedClientUser}
                >
                  Submit
                </Button>
              </Box>
            </>
          )}
        </Grid>
      )}

      <Grid
        item
        xl={4}
        md={4}
        sm={6}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: {
            xl: "center",
            sm: "center,",
          },
          gap: "15px",
        }}
      >
        <TextField
          onChange={(e) =>
            dispatch({ type: "globalSearch", payload: e.target.value })
          }
          value={globalSearch}
          id="outlined-basic"
          variant="outlined"
          style={{ width: "250px" }}
          size="small"
        />
        <SearchIcon
          onClick={handleSearch}
          style={{ fontSize: "35px", color: "#2d2d2d", cursor: "pointer" }}
        />
        <CleaningServicesIcon
          onClick={handleClear}
          style={{ fontSize: "34px", color: "#2d2d2d", cursor: "pointer" }}
        />
      </Grid>

      <Grid
        item
        xl={4}
        md={4}
        sm={12}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: {
            xl: "flex-end",
            sm: "flex-end",
          },
          gap: "15px",
        }}
      >
        {pathName === "Lead" && (
          <PersonAddIcon
            onClick={() => {
              dispatch({ type: "isNewLead", payload: true });
            }}
            sx={{ cursor: "pointer", fontSize: "35px", color: "#2d2d2d" }}
          />
        )}

        <ChecklistIcon
          sx={{ cursor: "pointer", fontSize: "35px", color: "#2d2d2d" }}
          onClick={handleSaveColumns}
        />

        {featureAccessible.find(
          (feature) => feature.sub_feature_id === 84 && feature.is_edit == true
        ) ? (
          <DeleteIcon
            sx={{ cursor: "pointer", fontSize: "35px", color: "#2d2d2d" }}
            onClick={handleDeleteModal}
          />
        ) : (
          <DeleteIcon
            sx={{ cursor: "pointer", fontSize: "35px", color: "#2d2d2d" }}
            disabled
          />
        )}
      </Grid>

      {isDelete && (
        <DeleteModal dispatch={dispatch} handleDelete={handleDelete} />
      )}

      {isNewLead && <Popupmodel show1={isNewLead} closeModel={dispatch} />}
    </Grid>
  );
};

export default Header;
