import { makeStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Card,
  Button,
  Stack,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  InputLabel,
  DialogTitle,
  TextField,
  CardActions,
  CardContent,
  Grid,
  Popover,
  FormControlLabel,
  RadioGroup,
  FormHelperText,
  Radio,
} from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useState, useRef } from "react";
import FormControl from "@mui/material/FormControl";
import { TextareaAutosize } from "@mui/base";
import Select from "@mui/material/Select";
import ProgramPopup from "./ProgramPopup";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Edit from "../../../assets/Edit.png";
import AddIcon from "@mui/icons-material/Add";
import LinkIcon from "@mui/icons-material/Link";
import ExtensionIcon from "@mui/icons-material/Extension";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CloseIcon from "@mui/icons-material/Close";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import {
  useParams,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import { useEffect } from "react";
import ProgramDetails from "../ProgramDetails";
import SelectPaymentMode from "../../../components/FeedBackModals/SelectPaymentMode";
import AlertPopup from "../../Clients/clientPlans/WorkoutPlan/AlertPopup";
import Box from "@mui/material/Box";

import Extension from "./modals/Extension";
import { FreezeProgram } from "./modals/FreezeProgram";
import EndProgram from "./modals/EndProgram";
import { HistoryOutlined } from "@material-ui/icons";
import HistoryPopup from "./modals/HistoryPopup";
import moment from "moment";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      border: "1px solid #D0D0D0",
    },
  },
});
const boldLabelStyle = {
  // fontWeight: 'bold',
  color: "#000000",
};

// const myBorder ={
//   // borderRadius:'10px',

//   background:'#D0D0D0'
// }
const ProgramList = () => {
  // Freeze and End Program
  const [isFreezeOpen, setIsFreezeOpen] = useState(false);
  const [isEndProgram, setIsEndProgram] = useState(false);
  // -----------------
  let params = useParams();
  const routeParams = useParams();
  let history = useHistory();

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [productList, setProductList] = useState([]);

  const [client, setClient] = useState("");
  const [show, setShow] = useState(false);
  const [paymentLink, setPaymentLink] = useState("");

  const [openSDPopup, setOpenSDPopup] = useState(false);
  const startDateRef = useRef();
  const [startDate, setStartDate] = useState();
  const [startDateErrMsg, setStartDateErrMsg] = useState(false);
  const [dealSize, setDealSize] = useState("");
  const [disableUpdate, setDisableUpdate] = useState(false);
  const [editId, setEditId] = useState("");
  const [programId, setProgramId] = useState("");
  const [leadProgramList, setLeadProgramList] = useState([]);

  const [openPaymentPopup, setOpenPaymentPopup] = useState(null);
  const paymentPopup = Boolean(openPaymentPopup);
  const [amountErrMsg, setAmountErrMsg] = useState(false);
  const [disablePaymentBtn, setDisablePaymentBtn] = useState(false);
  const [amount, setAmount] = useState("");
  const [dealAmount, setDealAmount] = useState();
  const [paymentType, setPaymentType] = useState("");
  const [paymentDate, setPaymentDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [description, setDescription] = useState("");
  const [typeErrMsg, setTypeErrMsg] = useState(false);
  const [dateErrMsg, setDateErrMsg] = useState(false);
  const [isErrImg, setIsErrImg] = useState(false);

  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isRenewalProgram, setIsRenewalProgram] = useState(false);

  // const [paymentDate, setPaymentDate] = useState('')
  const [openPaymentAlert, setOPenPaymentAlert] = useState(false);
  const [snackMessage, setsnackMessage] = useState("");
  const [snackBarColor, setSnackBarColor] = useState("");

  const [showExtension, setShowExtension] = useState(false);
  const [historyPopup, setHistoryPopup] = useState(false);

  const [StopFreeze , setStopFreeze]=useState(false)
  const [productStartdate, setProductStartdate] = useState('');
  //
  const [parentPage, setParentPage] = useState(
    history.location.pathname.split("/")[1] == "Clients" ? true : false
  );
 const [productEnddate, setProductEnddate] = useState('');
 const[extensionCheck,setExtensionCheck] = useState(false)
  // const []

  const openMenu = Boolean(anchorEl);

  const imageObj = {
    paymentImage: [],
    selectedImage: [],
  };

  const fileInputRef = useRef(null);
  const [paymentImage, setPaymentImage] = useState(imageObj);

  const handleFileChange = (event) => {
    // const file = event.target.files[0];
    const files = event.target.files;
    let updatedDisplayImage = [];
    let updatedSelectedImage = [];

    if (files.length > 3) {
      toast.error("Please select up to three images.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    for (const file of files) {
      if (file && file.size < 2097152) {
        if (file && file.type.startsWith("image/")) {
          const reader = new FileReader();

          reader.onload = (e) => {
            updatedDisplayImage.push(file);
            updatedSelectedImage.push(e.target.result);

            let updateProfile;

            updateProfile = {
              paymentImage: updatedDisplayImage,
              selectedImage: updatedSelectedImage,
            };
            setPaymentImage(updateProfile);
          };

          reader.readAsDataURL(file);
        }
      } else if (file) {
        fileInputRef.current.value = "";
        toast.error("Please select an image less than 2MB", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  const handleCloseSnackbar = () => {
    setOPenPaymentAlert(false);
  };

  const openPopup = (e) => {
    // setOpen(true);
    setIsOpenMenu(e);
  };
  const closePopup = () => {
    setOpen(false);
  };

  const handleNewProgram = () => {
    setOpen(true);
    setIsRenewalProgram(false);
    setIsOpenMenu(false);
  };

  const handleAddOnProgram = () => {
    setOpen(true);
    setIsRenewalProgram(false);
    setIsOpenMenu(false);
  };

  const handleRenewalProgram = () => {
    setOpen(true);
    setIsRenewalProgram(true);
    setIsOpenMenu(false);
  };

  const handleClick = (event, obj) => {
    setAnchorEl(event.currentTarget);
    setClient(obj);
    setEditId(obj.id);
    setProgramId(obj.product_details.id);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setShowExtension(false);
    setIsOpenMenu(false);
  };

  const handleClickOpenSDPopup = () => {

    console.log(client , 'client')
    if (client.isEndRequest) {
      toast.warning("Program is already ended", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (client.is_extended) {
      toast.warning("you can not able to access the Extened program", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if(client?.balance==0){
      toast.warning("you can not allowed to Edit after Payment", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    setStartDate("");
    setOpenSDPopup(true);
    setAnchorEl(null);
  };

  // edit module

  const handleCloseSDPopup = () => {
    setOpenSDPopup(false);
  };
  const handleChangeStartDate = (e) => {

    // console.log(moment(productEnddate),(moment(e.target.value)),'client')
    
    // if(productEnddate != '' && moment(productEnddate).isAfter(moment(e.target.value))){
    //   toast.warning(`Please fill the valid date`, {
    //     position: toast.POSITION.TOP_CENTER,
    //   });
    //   return false
    // }
      setStartDate(e.target.value);
    if (e.target.value === "") {
      setStartDateErrMsg(true);
      return false;
    } else {
      setStartDateErrMsg(false);
    }
  };
  const handleDealSize = (e) => {
    setDealSize(e.target.value);
  };

  const updateStartDate = () => {
    setDisableUpdate(true);

    axiosFetch({
      method: "put",
      url: `/updateProductAddedToLeadClient/${editId}`,
      requestConfig: {
        start_date: !!startDate ? startDate : "",
        prod_id: !!programId ? programId : "",
        deal_size: !!dealSize ? dealSize : undefined,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setDisableUpdate(false);
          toast.success("Updated Successfully!", {
            position: toast.POSITION.TOP_CENTER,
          });
          handleCloseSDPopup();
          getProductList();
        } else if (res.status === 400) {
          setDisableUpdate(false);
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // const extendTenureDate = () => {
  //   setDisableUpdate(true);

  //   axiosFetch({
  //     method: "put",
  //     url: `/updateProductAddedToLeadClient/${editId}`,
  //     requestConfig: {
  //       extension: true,
  //       role:!!Role?Role:"",
  //       end_date: !!endDate ? endDate : "",
  //       add_days:!!add_days ? add_days:"",
  //       remarks:!!remarks?remarks:"",
  //       prod_id: !!programId ? programId : "",

  //     },
  //   })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setDisableUpdate(false);
  //         toast.success("Updated Successfully!", {
  //           position: toast.POSITION.TOP_CENTER,
  //         });
  //         handleCloseSDPopup();
  //         getProductList();
  //       } else if (res.status === 400) {
  //         setDisableUpdate(false);
  //         toast.error("Bad Request", {
  //           position: toast.POSITION.TOP_CENTER,
  //         });
  //       } else if (res.status === 401) {
  //         history.push("/");
  //       }
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //     });
  // };

  // Add Payment

  const handleClickOpenPayment = async () => {
    const isBasicDetails = await axiosFetch({
      method: "get",
      url: `checkLeadBasicInfo?leadId=${routeParams?.id}`,
    });

    const fields = isBasicDetails?.data?.fields;

    const missingFields = Object.keys(fields).filter(
      (field) => fields[field] === false
    );

    if (missingFields.length > 0) {
      const fieldNames = missingFields
        .map((field) => {
          return field
            .replace(/_/g, " ")
            .replace(/([A-Z])/g, " $1")
            .toLowerCase();
        })
        .join(", ");

      toast.warning(`Please fill the following fields: ${fieldNames}`, {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (client.isEndRequest) {
      toast.warning("Program is already ended", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (client?.status === "Extension") {
      toast.warning(
        "you can't able to make the payment to the Extened program",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      return;
    }

    if (client?.status !== "Partially Paid" && client.is_extended) {
      toast.warning("you can't able to access the Extened program", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }


    if (client?.balance == 0 ) {
      toast.warning("Payment already compelted", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    setPaymentImage(imageObj);
    setOpenPaymentPopup(true);
    setAnchorEl(null);

    setDealAmount(+client?.deal_size);
  };

  const handleClosePayment = () => {
    setAmountErrMsg(false);
    setDisablePaymentBtn(false);
    setOpenPaymentPopup(false);
    clearPayment();
  };

  const clearPayment = () => {
    setAmount("");
    setPaymentDate(new Date().toISOString().split("T")[0]);
    setPaymentType("");
    setDescription("");
  };
  const handleChangeAmount = (e) => {
    setAmount(e.target.value);
    if (e.target.value === "") {
      setAmountErrMsg(true);
      return false;
    } else {
      setAmountErrMsg(false);
    }
  };

  const handlePaymentType = (e) => {
    setPaymentType(e.target.value);
    if (e.target.value === "") {
      setTypeErrMsg(true);
      return false;
    } else {
      setTypeErrMsg(false);
    }
  };

  const handleChangePaymentDate = (e) => {
    setPaymentDate(e.target.value);
    if (e.target.value === "") {
      setDateErrMsg(true);
      return false;
    } else {
      setDateErrMsg(false);
    }
  };

  const handleExtension = () => {
    if (client?.status === "Extension") {
      toast.warning("Program is already Extended", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (client?.balance != 0) {
      toast.warning("Please finalize your payment to continue", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (client.isEndRequest) {
      toast.warning("Program is already ended", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if(client.isFreezeActive){
      toast.warning("Account is on pause. Resume and add extension.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (!client.is_extended) {
      setShowExtension(true);
      setAnchorEl(null);
    } else {
      toast.warning(
        "you can not able to Extend the program more than one time",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
  };

  const handleChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  const handlePayment = async (clientId) => {
    if (
      amount === "" ||
      paymentType === "" ||
      paymentDate === "" ||
      !paymentImage.selectedImage.length
    ) {
      toast.error("Please select all the required Filed", {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    if (amount === "") {
      setAmountErrMsg(true);
      return false;
    }
    if (paymentType === "") {
      setTypeErrMsg(true);
      return false;
    }
    if (paymentDate === "") {
      setDateErrMsg(true);
      return false;
    }

    if (!paymentImage.selectedImage.length) {
      setIsErrImg(true);
      return;
    }
    if (
      amount !== "" &&
      paymentType !== "" &&
      paymentDate !== "" &&
      clientId !== undefined
    ) {
      // let URL = ``;
      let payload = {
        Amount: !!amount ? amount : undefined,
        Type: !!paymentType ? paymentType : undefined,
        leadClientProductId: editId,
        Description: description,
        paymentDate: paymentDate,
        paymentImage: paymentImage.paymentImage,
        balance: client?.balance
          ? client?.balance - amount
          : dealAmount - amount,
      };

      const formData = new FormData();

      // for (const key in payload) {
      //   formData.append(key, payload[key]);
      // }

      for (const key in payload) {
        if (key === "paymentImage") {
          for (const file of payload[key]) {
            formData.append(key, file);
          }
        } else {
          formData.append(key, payload[key]);
        }
      }

      // for (const pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1], "checkkkk data");
      // }

      setDisablePaymentBtn(true);

      axiosFetch({
        method: "post",
        url: `/addPayment`,
        requestConfig: formData,
      }).then((res) => {
        if (res.status === 200) {
          toast.success("Payment made successfully", {
            position: toast.POSITION.TOP_CENTER,
          });

          handleClosePayment();
          getProductList();
          setPaymentImage(imageObj);
          setIsErrImg(false);
          // getupdatevalue();
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      });
    }
  };
  // crate payment link

  const handlePaymentModal = (e) => {
    if (client.isEndRequest) {
      toast.warning("Program is already ended", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    if (client.is_extended) {
      toast.warning("you can not able to access the Extened program", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    setShow(true);
  };

  const closeModel = () => {
    setShow(false);
    setPaymentLink(false);
  };
  const submitHandler = async (paymentType, amount) => {
    try {
      const paymentObj = {
        payment_gateway: paymentType,
        amount: amount,
        lead_client_product_id: client.id,
        old_payment_link: client.payment_link,
        balance: client?.balance
          ? client?.balance - amount
          : dealAmount - amount,
      };

      const isBasicDetails = await axiosFetch({
        method: "get",
        url: `checkLeadBasicInfo?leadId=${routeParams?.id}`,
      });

      if (!isBasicDetails.data) {
        toast.warning("Please fill all the Basic Information", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      const response = await axiosFetch({
        url: `/createPaymentLink-program`,
        requestConfig: paymentObj,
        method: "post",
      });
      if (response.status === 200) {
        setPaymentLink(response.data.link);
        setShow(false);
        setOPenPaymentAlert(true);
        setsnackMessage(
          "Your payment link is sent to your email please check your email"
        );
        setSnackBarColor("success");
      }
    } catch (error) {
      // console.error(error);
    }
  };

  const utcFormater = (utc) => {
    let inputDate;
    let formattedDate;

    if (utc) {
      const utcTimestamp = utc;
      const dateInUTC = new Date(utcTimestamp);
      const localTime = dateInUTC.toLocaleDateString("en-GB");
      inputDate = localTime.split(",");

      const [day, month, year] = inputDate[0].split("/");
      formattedDate = `${day.padStart(2, "0")}-${month.padStart(
        2,
        "0"
      )}-${year}`;
    } else {
      return [];
    }

    return [formattedDate, inputDate];
  };

  const getProductList = async () => {
    const response = await axiosFetch({
      url: `getProductsByLeadId/${params.id}`,
      method: "get",
    });

    if (response.status === 200) {
      const copiedArray = [...response.data];

      // copiedArray.forEach((item) => {
      //   item.end_date = item.end_date ? item.end_date.split("T")[0] : "";
      //   item.start_date = item.start_date ? item.start_date.split("T")[0] : "";
      // });

      setProductList(copiedArray);
    }
  };

  // Freeze and End Program

  const handleFreezeClose = () => {
    setIsFreezeOpen(false);
    setIsEndProgram(false);
  };

  const handleFreeze = () => {

    console.log(client , 'client')
    if (client.isEndRequest) {
      toast.warning("Program is already ended", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (client?.balance != 0) {
      toast.warning(
        "you can not able to Freeze before payments",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      return;
    }

    // if (client.is_extended) {
    //   toast.warning("you can not able to access the Extened program", {
    //     position: toast.POSITION.TOP_CENTER,
    //   });
    //   return;
    // }

    // if (client?.freezeRequest) {
    //   toast.warning(
    //     "you can not able to Freeze the program more than one time",
    //     {
    //       position: toast.POSITION.TOP_CENTER,
    //     }
    //   );
    //   return;
    // }
    setIsFreezeOpen(true);
    setAnchorEl(false);
  };


  const handleEditFreeze =async()=>{
    console.log(client , 'client')
    if (client.isEndRequest) {
      toast.warning("Program is already ended", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    setIsEndProgram(true);
    setAnchorEl(false);
    setStopFreeze(true)
  }

  const handleHistory = () => {
    setHistoryPopup(true);
    setAnchorEl(false);
  };
  const handleHistoryClose = () => {
    setHistoryPopup(false);
  };
  const handleEndProgram = () => {
    if (client.isEndRequest) {
      toast.warning("Program is already ended", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    
    if (client?.balance != 0) {
      toast.warning(
        "you can not able to End Program before payments",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      return;
    }

    // if (client.is_extended) {
    //   toast.warning("you can not able to access the Extened program", {
    //     position: toast.POSITION.TOP_CENTER,
    //   });
    //   return;
    // }

    setIsEndProgram(true);
    setAnchorEl(false);
    setStopFreeze(false)
  };

  const handleAddonProgram = () => {
    console.log(client, "client");

    const endDate = moment(client.end_date);
    const now = moment();

    console.log(endDate, "endDate");

    if (client?.balance !=0) {
      toast.warning(
        'Programs that are not marked as "Paid" cannot have an addon program added.',
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      return;
    }

    if (endDate < now) {
      toast.warning("Ended Programs cannot have an addon program added.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if(client.isFreezeActive){
      toast.warning("Resume your program to add the addon program.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    handleAddOnProgram();
    // setIsEndProgram(true);
    // setAnchorEl(false);
  };

  // -----------------

  useEffect(() => {
    getProductList();
  }, []);

  useEffect(() => {
    if (productList.length > 0) {
      const lastProduct = productList[productList.length - 1];
      if(!lastProduct){
        setShowExtension(false)
      }

      const maxEndDate = productList.reduce((max, product) => {
        return new Date(product.end_date) > new Date(max) ? product.end_date : max;
    }, lastProduct.end_date);
      setProductStartdate(lastProduct.start_date);
      setProductEnddate(maxEndDate);


      
    } else {
      setExtensionCheck(false);
      console.log('No products in the list.');
    }
  }, [productList]);
  return (
    <div>
      <div sx={{ width: "-webkit-fill-available !important" }}>
        <div style={{ margin: "22px" }}>
          <br />

          <Stack direction="row" spacing={6}>
            <Typography
              sx={{
                color: "#2D2D2D",
                fontFamily: "Poppins",
                fontWeight: "600",
                Size: "18px",
              }}
            >
              Programs
            </Typography>
            <Button
              variant="contained"
              sx={{
                color: "white",
                backgroundColor: "black",
                textTransform: "capitalize",
                marginLeft: "80% !important",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
              }}
              onClick={(e) => openPopup(e.target)}
            >
              Add Program
            </Button>
          </Stack>
          <Menu
            id="basic-menu"
            anchorEl={isOpenMenu}
            open={isOpenMenu}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {parentPage ? (
               <MenuItem onClick={handleRenewalProgram}>Renewal Program</MenuItem>
            ) : (
              <MenuItem onClick={handleNewProgram}>New Program</MenuItem>
            )}
           
          </Menu>
          <br />
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead sx={{ backgroundColor: "#F4F4F4" }}>
                <TableRow>
                  <TableCell align="left">
                    <b>S.No</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Program Name</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Program Duration</b>
                  </TableCell>
                  <TableCell>
                    <b>Program Duration Units</b>
                  </TableCell>
                  <TableCell align="left" sx={{ width: "110px" }}>
                    <b>Start Date</b>
                  </TableCell>
                  <TableCell align="left" sx={{ width: "110px" }}>
                    <b>End Date</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Deal Size</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Deal Close Date</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Program Status</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Seller</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Remarks</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Action</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productList.length > 0 &&
                  productList.map((item, index) => (
                    <TableRow sx={{ color: "#6B6B6B" }} key={index}>
                      <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                        {index + 1}
                      </TableCell>
                      <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                        {item.product_details?.name}{" "}
                        {item.isAddOnProgram && "(AddOnProgram)"}
                      </TableCell>
                      <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                        {item?.duration?.duration}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: "#6B6B6B" }}
                      >
                        {item.duration?.unit}
                      </TableCell>
                      <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                        {utcFormater(item.start_date)[0]}
                      </TableCell>
                      <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                        {utcFormater(item.end_date)[0]}
                      </TableCell>
                      <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                        {item.deal_size}
                      </TableCell>
                      <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                        {utcFormater(item.expected_close_date)[0]}
                      </TableCell>
                      <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                        {item.status === "freezed" ? "Paused" : item.status}
                      </TableCell>
                      <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                        {item?.addedBy}
                      </TableCell>
                      <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                        {item.remarks}
                      </TableCell>
                      <TableCell align="left">
                        <IconButton
                          aria-label="action-button"
                          // onClick={handleClick}
                          onClick={(e) => handleClick(e, item)}
                        >
                          <MoreHorizIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {
              client.balance != 0 && 
              <MenuItem
              onClick={handleClickOpenPayment}
              disabled={client?.status === "Fully Paid"}
            >
              <AddIcon />
              &nbsp;Add Payment
            </MenuItem>
            }
            
            <hr />
            {(client.isEditable && client.product_payment.length == 0) && (
              <MenuItem onClick={handleClickOpenSDPopup}>
                <img
                  src={Edit}
                  style={{
                    width: "20.12px",
                    height: "20.12px",
                    top: "1.88px",
                    left: "2px",
                    border: "2px",
                  }}
                />
                &nbsp; Edit
              </MenuItem>
            )}

            <hr />
            
            {
                client.balance != 0 && 
                <MenuItem
                onClick={handlePaymentModal}
                disabled={client?.status === "Fully Paid"}
              >
                <LinkIcon /> &nbsp; Create Payment Link
              </MenuItem>
            }
        
            <hr />
            <MenuItem onClick={handleExtension}>
              <ExtensionIcon />
              &nbsp; Extension
            </MenuItem>
            <hr />
            <MenuItem onClick={handleEndProgram}>
              <CalendarTodayIcon />
              &nbsp; End program
            </MenuItem>
            <hr />
            {
              !client.isAddOnProgram && <MenuItem onClick={handleFreeze}>
              <AcUnitIcon />
              &nbsp; Pause program
            </MenuItem>
            }
            
            <hr />
            { 
              client.isFreezeActive   &&
              <MenuItem onClick={handleEditFreeze}>
              <AddIcon />
                &nbsp; Stop Pause
              </MenuItem>
            }
          
           
            <hr />
            <MenuItem onClick={handleHistory}>
              <HistoryOutlined />
              &nbsp; History
            </MenuItem>

            <hr />

            {
             (parentPage && client.balance ==0 && !client.isAddOnProgram) &&
            
              <MenuItem onClick={handleAddonProgram}>
                <AddCircleIcon />
                &nbsp; Add On Program
              </MenuItem>
           
            }
           
           </Menu>
          {/* <TablePagination
    component="div"
    count={100}
    page={page}
    onPageChange={handleChangePage}
    rowsPerPage={rowsPerPage}
    onRowsPerPageChange={handleChangeRowsPerPage}
  /> */}

          {open ? (
            <ProgramPopup
              close={closePopup}
              getProductList={getProductList}
              isRenewalProgram={isRenewalProgram}
              addOnProgram={parentPage}
              clientProductId={client.id}
              startDate = {productStartdate}
              endDate={productEnddate}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <Popover
        anchorEl={paymentPopup}
        open={openPaymentPopup}
        onClose={handleClosePayment}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // anchorOrigin={{
        //   vertical: "center",
        //   horizontal: "center",
        // }}
        // transformOrigin={{
        //   vertical: "center",
        //   horizontal: "left",
        // }}
        style={{
          width: "850px",
          height: "600px",
          top: "50%",
          left: "50%",
          marginTop: "-300px",
          marginLeft: "-350px",
        }}
      >
        <DialogTitle id="alert-dialog-title">Add Payment</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <label>
                Amount<span style={{ color: "red" }}>&nbsp;*</span>
              </label>
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                //label="Amount"
                // variant="outlined"
                value={dealAmount}
                disabled
                sx={{ width: "225px" }}
                type="number"
              />
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <label>
                Payment Amount<span style={{ color: "red" }}>&nbsp;*</span>
              </label>
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                // label="Amount"
                // variant="outlined"
                value={amount}
                onChange={handleChangeAmount}
                sx={{ width: "225px" }}
                type="number"
              />
              <FormHelperText sx={{ color: "red" }}>
                {amountErrMsg ? "Please Enter Something" : ""}
              </FormHelperText>
            </Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <label>
                Balance Amount<span style={{ color: "red" }}>&nbsp;*</span>
              </label>
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                // label="Amount"
                // variant="outlined"
                disabled
                value={
                  client?.balance
                    ? client?.balance - amount
                    : dealAmount - amount
                }
                sx={{ width: "225px" }}
                type="number"
              />
            </Grid>
            <Grid item xs={4}></Grid>
            <br />
            <br />
            <Grid item xs={4}>
              <label>
                Payment Type<span style={{ color: "red" }}>&nbsp;*</span>
              </label>
            </Grid>
            <Grid item xs={4}>
              <FormControl>
                <InputLabel id="demo-simple-select-label">
                  Payment Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={paymentType}
                  label="Payment Type"
                  onChange={handlePaymentType}
                  sx={{ width: "225px" }}
                >
                  <MenuItem value="Cash">Cash</MenuItem>
                  <MenuItem value="Cheque">Cheque</MenuItem>
                  <MenuItem value="Card">Card</MenuItem>
                  <MenuItem value="Net Banking">Net Banking</MenuItem>
                  <MenuItem value="Paytm">Paytm</MenuItem>
                  <MenuItem value="Razorpay">Razorpay</MenuItem>
                  <MenuItem value="Upi">Upi</MenuItem>
                  <MenuItem value="Phonepe">Phonepe</MenuItem>
                  <MenuItem value="Gpay">Gpay</MenuItem>
                  <MenuItem value="Easypay">Easypay</MenuItem>
                  <MenuItem value="Others">Others</MenuItem>
                </Select>
              </FormControl>
              <FormHelperText sx={{ color: "red" }}>
                {typeErrMsg ? "Please Select Something" : ""}
              </FormHelperText>
            </Grid>
            <Grid item xs={4}></Grid>
            <br />
            <br />

            <Grid item xs={4}>
              <label>
                Payment Date<span style={{ color: "red" }}>&nbsp;*</span>
              </label>
            </Grid>
            <Grid item xs={4}>
              <input
                placeholder="Payment Date"
                // className="textbox-n"
                type="date"
                value={paymentDate}
                // min={new Date().toISOString().split("T")[0]}
                onChange={handleChangePaymentDate}
                style={{ width: "100%" }}
                onKeyDown={(e) =>
                  new Date().toISOString().split("T")[0] && e.preventDefault()
                }
              />
              <FormHelperText sx={{ color: "red" }}>
                {dateErrMsg ? "Please Select Something" : ""}
              </FormHelperText>
            </Grid>
            <Grid item xs={4}></Grid>
            <br />
            <br />

            <Grid item xs={4}>
              <label>Description</label>
            </Grid>
            <Grid item xs={4}>
              <TextareaAutosize
                aria-label="empty textarea"
                placeholder="Empty"
                value={description}
                onChange={handleChangeDescription}
                style={{
                  padding: "12px",
                  width: "225px",
                }}
              />
            </Grid>
            <Grid item xs={4}></Grid>
            <br />
            <br />
            <Grid item xs={4}>
              <label>
                Payment Image<span style={{ color: "red" }}>&nbsp;*</span>
              </label>
            </Grid>
            <Grid item xs={8} sx={{ display: "flex" }}>
              {paymentImage.selectedImage.length ? (
                <>
                  {paymentImage.selectedImage.map((img) => (
                    <div
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "3.333px",
                        border: "1px solid #D7D7D7",
                        background: "#F8F8F8",
                        cursor: "pointer",
                        position: "relative",
                        marginRight: "40px",
                      }}
                    >
                      <input
                        type="file"
                        style={{
                          width: "396px",
                          height: "180px",
                          position: "absolute",
                          opacity: 0,
                        }}
                        multiple
                        onChange={(e) => handleFileChange(e)}
                      />
                      <img
                        alt="paymentphoto"
                        src={img}
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </div>
                  ))}
                </>
              ) : (
                <div
                  style={{
                    width: "230px",
                    height: "100px",
                    borderRadius: "3.333px",
                    border: "1px solid #D7D7D7",
                    background: "#F8F8F8",
                    cursor: "pointer",
                    position: "relative",
                    marginRight: "40px",
                  }}
                >
                  <input
                    type="file"
                    style={{
                      width: "396px",
                      height: "180px",
                      position: "absolute",
                      opacity: 0,
                    }}
                    multiple
                    onChange={(e) => handleFileChange(e)}
                  />

                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CameraAltIcon
                      sx={{ color: "#989898", width: "42px", height: "34px" }}
                    />
                    <Typography sx={{ color: "#989898", fontSize: "14px" }}>
                      {"Add Payment image"}
                    </Typography>
                    <Typography sx={{ color: "#D7D7D7", fontSize: "12px" }}>
                      {"JPG, GIF or PNG, Max size of 2MB"}
                    </Typography>
                  </div>

                  <FormHelperText sx={{ color: "red" }}>
                    {isErrImg ? "Please Select Image" : ""}
                  </FormHelperText>
                </div>
              )}

              {/* <div
                style={{
                  width: "230px",
                  height: "100px",
                  borderRadius: "3.333px",
                  border: "1px solid #D7D7D7",
                  background: "#F8F8F8",
                  cursor: "pointer",
                  position: "relative",
                  marginRight: "40px",
                }}
              >
                <input
                  type="file"
                  style={{
                    width: "396px",
                    height: "180px",
                    position: "absolute",
                    opacity: 0,
                  }}
                  multiple
                  onChange={(e) => handleFileChange(e)}
                />

                {paymentImage.selectedImage ? (
                  <img
                    alt="paymentphoto"
                    src={paymentImage.selectedImage}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CameraAltIcon
                      sx={{ color: "#989898", width: "42px", height: "34px" }}
                    />
                    <Typography sx={{ color: "#989898", fontSize: "14px" }}>
                      {"Add Payment image"}
                    </Typography>
                    <Typography sx={{ color: "#D7D7D7", fontSize: "12px" }}>
                      {"JPG, GIF or PNG, Max size of 2MB"}
                    </Typography>
                  </div>
                )}
              </div> */}
            </Grid>
            <Grid item xs={4}></Grid>

            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <Stack direction="row">
                <DialogActions>
                  <Button onClick={handleClosePayment} variant="contained">
                    Cancel
                  </Button>
                  <Button
                    onClick={handlePayment}
                    variant="contained"
                    disabled={disablePaymentBtn}
                  >
                    Make Payment
                  </Button>
                </DialogActions>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
      </Popover>
      <Dialog
        open={openSDPopup}
        onClose={handleCloseSDPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Edit</DialogTitle>
        <DialogContent>
          <input
            placeholder="Start Date"
            class="textbox-n"
            type="text"
            onFocus={() => (startDateRef.current.type = "date")}
            onBlur={() => (startDateRef.current.type = "text")}
            ref={startDateRef}
            value={startDate}
            min={new Date().toISOString().split("T")[0]}
            onChange={handleChangeStartDate}
            onKeyDown={(e) =>
              new Date().toISOString().split("T")[0] && e.preventDefault()
            }
          />

          <br />
          <br />

          <TextField
            placeholder="Deal Size"
            value={dealSize}
            onChange={handleDealSize}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSDPopup}>Cancel</Button>
          <Button onClick={updateStartDate} autoFocus disabled={disableUpdate}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
      {show && (
        <SelectPaymentMode
          paymentLink={paymentLink}
          oldPaymentLink={client.payment_link}
          programUnit={client.product_details.duration_unit}
          programDuration={client.product_details.duration}
          programName={client.product_details.name}
          closeModel={closeModel}
          submitForm={submitHandler}
          paymentType={client.payment_gateway}
          paymentAmount={client.payment_amount}
          currentRow={client}
        />
      )}
      <AlertPopup
        snackMessage={snackMessage}
        snackBarColor={snackBarColor}
        open={openPaymentAlert}
        handleCloseSnackbar={handleCloseSnackbar}
      />

      {showExtension && (
        <Extension
          closeModel={handleClose}
          item={client}
          onUpdateProgram={getProductList}
        />
      )}
      <FreezeProgram
        isOpen={isFreezeOpen}
        client={client}
        onClose={handleFreezeClose}
        reLoadList={getProductList}
      />

      <EndProgram
        isOpen={isEndProgram}
        client={client}
        onClose={handleFreezeClose}
        reLoadList={getProductList}
        EndProgram={StopFreeze}
      />

      {historyPopup && (
        <HistoryPopup
          isOpen={historyPopup}
          onClose={handleHistoryClose}
          client={client}
          
        />
      )}
    </div>
  );
};

export default ProgramList;
