import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";

import "./TaskModel.css";

import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import { toast } from "react-toastify";

import { useLocation } from "react-router-dom";

import Button from "../CustomButton/Button";
import { axiosFetch } from "../../Utlis/AxiosFetch";

const Backdrop = ({ closeModel }) => {
  return <div className={"backdrop"} onClick={closeModel}></div>;
};

const convertUtcToLocalTime = (utc) => {
  const utcTimestamp = utc;
  const dateInUTC = new Date(utcTimestamp);
  const localTime = dateInUTC.toLocaleString();
  const inputDate = localTime.split(",");
  const formattedDate = inputDate[0].replace(
    /^(\d{2})\/(\d{2})\/(\d{4})$/,
    "$3-$1-$2"
  );

  return [formattedDate, inputDate];
};

const TaskForm = ({ closeModel, leadId, editRow, isClient, reloadTable }) => {
  const location = useLocation().pathname.split("/")[1] === "myTask";
  const userId = JSON.parse(window.localStorage.getItem("operator")).id;

  // edit data state

  const [taskdata, setTaskData] = useState([]);
  const [taskDate, setTaskDate] = useState(null);
  const [taskTime, setTaskTime] = useState(null);
  const [resheduleDate, setResheduleDate] = useState("");

  // submit data state

  const [dueDate, setDueDate] = useState(null);
  const [time, setTime] = useState(null);
  const [taskType, setTaskType] = useState("");
  const [description, setDescription] = useState("");
  const [comments, setComments] = useState("");

  const handleDate = (e) => {
    const date = e.$d;
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const day = date.getDate().toString().padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    setDueDate(formattedDate);
  };

  const handleTime = (e) => {
    const time = e.$d;

    const hours = time.getHours();
    const minutes = time.getMinutes();
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;

    setTime(formattedTime);
  };

  const handleTaskType = (id) => {
    // const filterData = taskdata.filter((data) => data.id === +id);
    setTaskType(id);
  };

  const convertUTCFormat = (startDate, startTime) => {
    const event = new Date(`${startDate} ${startTime}`).toISOString();
    return event;
  };

  console.log(leadId, "testtt");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const createTaskData = {
      lead_id: leadId,
      user_id: userId,
      due_date: time ? convertUTCFormat(dueDate, time) : "",
      task_type: +taskType,
      description: description,
      // comments: comments,
      isClient,
    };

    let response;

    // create task

    if (!location) {
      // delete createTaskData.comments;

      if (!dueDate || !time || !taskType || !description) {
        toast.error("Fields cannot be left empty", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      response = await axiosFetch({
        url: `/createTask`,
        method: "post",
        requestConfig: createTaskData,
      });
    }

    // Edit Task

    if (location) {
      const oldDueDate = new Date(convertUtcToLocalTime(editRow?.due_date)[0]);
      const selectedDate = new Date(dueDate);

      function areDatesEqual(date1, date2) {
        return (
          date1.getFullYear() === date2.getFullYear() &&
          date1.getMonth() === date2.getMonth() &&
          date1.getDate() === date2.getDate()
        );
      }

      createTaskData.lead_id = editRow?.lead_id;
      delete createTaskData.user_id;
      // delete createTaskData.due_date;

      createTaskData.is_rescheduled = !areDatesEqual(oldDueDate, selectedDate);

      if (!description) {
        toast.error("Fields cannot be left empty", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      response = await axiosFetch({
        url: `/editTask?taskId=${editRow.id}`,
        method: "put",
        requestConfig: createTaskData,
      });
    }

    // resultd

    if (response.status === 200) {
      toast.success(response.data, {
        position: toast.POSITION.TOP_CENTER,
      });
      if (location) reloadTable();
      closeModel();
    }
  };

  const getTaskData = async () => {
    const response = await axiosFetch({
      url: `getTaskType`,
      method: "get",
    }).then((res) => res.data);

    setTaskData(response);
  };

  useEffect(() => {
    getTaskData();
  }, []);

  useEffect(() => {
    if (editRow) {
      let currentDate = new Date(editRow.due_date);
      currentDate.setDate(currentDate.getDate() + 2);
      setResheduleDate(currentDate);

      setTaskDate(dayjs(editRow.due_date));
      setTaskTime(
        dayjs(
          `${convertUtcToLocalTime(editRow.due_date)[0]} ${
            convertUtcToLocalTime(editRow.due_date)[1][1]
          }`
        )
      );
      setDescription(editRow.description);
      setTaskType(editRow.task_type);
      setComments(editRow.comments);
      setDueDate(convertUtcToLocalTime(editRow.due_date)[0]);
      setTime(convertUtcToLocalTime(editRow.due_date)[1][1]);
    }
  }, [editRow]);
  const DATE_FORMAT ='DD/MM/YYYY'
  return (
    <div className={"task-card"}>
      <div className={"task-title"}>
        <h3> {location ? "Edit Task" : "Add Task"}</h3>
      </div>
      <form className={"task-form w-100"} onSubmit={handleSubmit}>
        <div className={"grid-container"}>
          <div className={"item item1"}>
            <label htmlFor="propgramName" className="mb-2">
              Due Date
            </label>

            <div className="taskModal-input">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DesktopDatePicker
                    disablePast
                    onChange={handleDate}
                    value={taskDate}
                    maxDate={dayjs(resheduleDate)}
                    minDate={location ? dayjs(editRow?.due_date) : ""}
                    format={DATE_FORMAT} 
                    // disabled={location}
                  />
                </DemoItem>
              </LocalizationProvider>
            </div>
          </div>
          <div className={"item item2"}>
            <label htmlFor="propgramName" className="mb-2">
              Due Time
            </label>
            <div className="taskModal-input">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["TimePicker"]}>
                  <TimePicker
                    onChange={handleTime}
                    value={taskTime}
                    // disabled={location}
                    //  defaultValue={taskTime} never use while update this
                  />
                </DemoContainer>
              </LocalizationProvider>
            </div>
          </div>
          <div className={"item item3"}>
            <label htmlFor="propgramName" className="mb-2">
              Task Type
            </label>
            <select
              value={taskType}
              onChange={(e) => handleTaskType(e.target.value)}
              disabled={!!location}
            >
              <option defaultValue="">Select</option>
              {taskdata?.map((value, index) => (
                <option value={value.id} key={index}>
                  {value.task_type}
                </option>
              ))}
            </select>
          </div>
          <div className={"item item4"}>
            <label htmlFor="propgramName" className="mb-2">
              Description
            </label>
            <div>
              <textarea
                value={description}
                id="w3review"
                name="w3review"
                rows="4"
                cols="50"
                onChange={(e) => setDescription(e.target.value)}
                readOnly={!!location}
              ></textarea>
            </div>
          </div>
          {/* {location && (
            <div className={"item item5"}>
              <label htmlFor="propgramName" className="mb-2">
                Comments
              </label>
              <div>
                <textarea
                  value={comments}
                  id="w3review"
                  name="w3review"
                  rows="3"
                  cols="50"
                  onChange={(e) => setComments(e.target.value)}
                ></textarea>
              </div>
            </div>
          )} */}

          <div className="btn-container">
            <Button
              outline
              className={"task-button"}
              type={"button"}
              onClick={closeModel}
            >
              Close
            </Button>
          </div>
          <div className="btn-container">
            <Button inline className={"task-button"} type={"submit"}>
              Save
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

const TaskModal = ({ closeModel, leadId, editRow, isClient, reloadTable }) => {
  const backdropRoot = document.getElementById("backdrop-root");
  if (!backdropRoot) {
    return null;
  }
  const editTableModal = document.getElementById("open__modal-root");
  if (!editTableModal) {
    return null;
  }

  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop closeModel={closeModel} />,
        backdropRoot
      )}
      {ReactDOM.createPortal(
        <TaskForm
          closeModel={closeModel}
          leadId={leadId}
          editRow={editRow}
          isClient={isClient}
          reloadTable={reloadTable}
        />,
        editTableModal
      )}
    </>
  );
};

export default TaskModal;
