import React, {
  useState,
  useEffect,
  useMemo,
 useContext,
 useCallback,
} from "react";
import * as $ from "jquery";
import axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";
import "@coreui/coreui/dist/css/coreui.min.css";

import stateContext from "../../context/StateContext";
import { AgGridReact } from "ag-grid-react";
import { ATLAS_URI } from "../../Constants";
import PopupDietPlanView from "../Clients/PopupDietPlanView";
import ClientDietPlans from "../Clients/ClientDietPlans";
import { toast } from "react-toastify";
import { dateConverter } from "../../Utlis/dateConversion";
import moment from "moment";

function DietPlanListing() {
  const [showDetailPage, setShowDetailPage] = useState(false);
  const [dietPlanId, setDietPlanId] = useState(null);

  const [dietPlanView, setDietPlanView] = useState(false)
  const [dietPlanViewPlanId, setDietPlanViewPlanId] = useState('')

  const contextState = useContext(stateContext);

  const { configToken, operator, currentUser } = contextState.state;

  const [dietPlanList, setDietPlanList] = useState([]);

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const [clientInfo, setClientInfo] = useState({})

  const showDietPlanViewModal = (dietPlanId) => {
   
    setDietPlanView(true)
    setDietPlanViewPlanId(dietPlanId)
}



  const dietPlanPermission=contextState.state?.featureAccessible.find(
    (feature) => feature.sub_feature_id === 23
  )?.is_view && 'DIET PLAN'


  const myLeadsPermissionOnly =
  contextState.state?.featureAccessible.find(
    (feature) => feature.sub_feature_id == 44
  ).is_view &&
  contextState.state?.featureAccessible.find(
    (feature) => feature.sub_feature_id == 6
  ).is_view==false;



  const onGridReady = (params) => {
   
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const checkbox = (params) => {

    return params.node.group === true;
  };


  const getDietGoal = () => {

    let URL = `${ATLAS_URI}/listAllDietPlan`

    if(myLeadsPermissionOnly) URL = `${ATLAS_URI}/myClientPlan/diet`
    

    axios.get(`${URL}`, configToken).then(res => {
   
      if (res.status == 200) {
       
        setDietPlanList(res.data.map(planInfo => {
          planInfo.planName = planInfo?.plan_name
          planInfo.leadName = planInfo?.Lead?.name
          planInfo.phone = planInfo?.Lead?.contact_number
          planInfo.email = planInfo?.Lead?.email_id
          planInfo.assignedBy = planInfo?.Lead?.ClientInfo?.DietitianAssignedByInfo ? (planInfo?.Lead?.ClientInfo?.DietitianAssignedByInfo?.firstName + ' ' + (planInfo?.Lead?.ClientInfo?.DietitianAssignedByInfo?.lastName ? planInfo?.Lead?.ClientInfo?.DietitianAssignedByInfo?.lastName : '')) : ''
          planInfo.dateJoined = planInfo?.Lead?.createdAt ? moment(dateConverter(planInfo?.Lead?.createdAt)).format("DD/MM/YYYY") : ''
          planInfo.action = { leadId: planInfo?.leadId, planId: planInfo?.id }
          return planInfo
        }))
       
      }
    }).catch(err => {
      console.error('err', JSON.stringify(err, null, 2))
    
    })
  }

  useEffect(() => {

      

      if (dietPlanPermission == 'DIET PLAN') {

          getDietGoal()
      }



  }, []);

  
const [update, setUpdate] = useState(false)
  function editDietPlan(dietPlanId) {
  
    setDietPlanId(dietPlanId);
    setShowDetailPage(true);
    setUpdate(true)
    
  }


 


                                                   /* get plan name */

  const [genearteIds, setGenearteIds] = useState(1)
                                                           
                                                    



const defaultColDef = useMemo(() => {
  return {
    flex: 1,
    minWidth: 100,
    // allow every column to be aggregated
    enableValue: true,
    // allow every column to be grouped
    enableRowGroup: true,
    // allow every column to be pivoted
    enablePivot: true,
    sortable: true,
    filter: true,
  };
}, []);
const containerStyle = useMemo(() => ({ width: '100%', 'height': '1000px' }), []);
  const gridStyle = useMemo(() => ({ 'height': '100%', width: '100%' }), []);

  const paginationNumberFormatter = useCallback((params) => {
      return '[' + params.value.toLocaleString() + ']';
    }, []);

  return (
    <section className="profileInfo">     
      {
      dietPlanPermission == 'DIET PLAN'   ? (
          showDetailPage ? (
              <ClientDietPlans
                showDetailPage={showDetailPage}
                setShowDetailPage={setShowDetailPage}
                clientsData={clientInfo.id}
                dietPlanId={dietPlanId}
                setGenearteIds={setGenearteIds}
                update={update}
              />
            ) : (<section className="content">
          <div className="row">
              <div className="col-md-12">
              <div style={containerStyle}>
  <div style={gridStyle} className="ag-theme-alpine">

      
  <AgGridReact
      rowData={dietPlanList}
      columnDefs={[
          { field: 'planName' },
      { field: 'leadName' },
      { field: 'phone' },
      { field: 'email' },
      { field: 'assignedBy' },
      { field: 'dateJoined' },
      { field: 'action', cellRenderer: (params, id) => {
        
          return (<React.Fragment key={params.data.id}>
              <button
                className="btn btn-default btn-xs no-focus"
                title="View"
                onClick={() => showDietPlanViewModal(params.data.id)}
              >
                <i className="fas fa-eye" aria-hidden="true"></i>
              </button>
              
              <button
                className="btn btn-default btn-xs no-focus"
                title="Edit"
                onClick={() => {
                  editDietPlan(params.data.id)
                  setClientInfo(params.data.Lead)
              }}
              >
                <i className="fas fa-pencil" aria-hidden="true"></i>
              </button>

              <button className="btn btn-default btn-xs no-focus"
              title="Delete Plan"
              onClick={() => {
                axios.delete(`${ATLAS_URI}/deleteDietPlanByPlanId/${params.data.id}`, configToken).then(res => {
                  if (res.status == 200) {
                    // toast.error(err.response.data.message, { position: toast.POSITION.TOP_CENTER })
                    toast.success('Plan Deleted', { position: toast.POSITION.TOP_CENTER })

                    getDietGoal()

                  }
                }).catch(err => {
                  toast.error(err.response.data.message, { position: toast.POSITION.TOP_CENTER })
                })
              }}>
                  <i className="fas fa-trash" aria-hidden="true"></i>
                </button>

            </React.Fragment>)
      } }
  ]}
      defaultColDef={defaultColDef}
      onGridReady={onGridReady}
      sideBar={{
          position:'bottom',
          defaultToolPanel: 'columns',
        }}

        pagination={true}
        paginationPageSize={10}
        paginationNumberFormatter={paginationNumberFormatter}
    >

    </AgGridReact>
  </div>
</div>
          </div>
      </div>
      {
            dietPlanView && <PopupDietPlanView show1={dietPlanView} setShow1={setDietPlanView} planId={dietPlanViewPlanId} />
          }
      </section>)
      ) : (<></>)
      }
    </section>
  );
}

export default DietPlanListing;
