import React, { useState, useEffect, useContext } from "react";
import { EditTableRow, openDialog } from "../../Helpers/TableHelpers";
import axios from "axios";
import SelectBox from "../../components/SelectBox";
import BoxHeader from "../../components/BoxHeader";
import DataTable from "../../components/DataTable";
import Dialog from "../../components/Dialog";
import * as $ from "jquery";
import md5 from "md5";
import { changeHandler as oldChangeHandler } from "../../Helpers/ChangeHandler";
import { ADMIN, ATLAS_URI } from "../../Constants";
import stateContext from "../../context/StateContext";
import { useFormik } from "formik";
import { addUserSchema } from "../../ValidationSchema";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Switch from "@mui/material/Switch";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ImageUploading from "react-images-uploading";
import { Stack, Button, Typography } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { axiosFetch } from "../../Utlis/AxiosFetch";
import { useHistory } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Autocomplete, TextField } from "@mui/material";

function ManageUsers() {
  const history = useHistory();
  const contextState = useContext(stateContext);
  const { configToken, operator } = contextState.state;
  const [showreportManager, setShowreportManager] = useState(false);
  const [file, setFile] = useState();
  const [show, setShow] = useState(false);
  let [role, setRole] = useState("");

  const [images, setImages] = React.useState([]);
  const maxNumber = 3;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex, putImageId);
    setImages(imageList);
    //upload if id
    if (putImageId && addUpdateIndex) {
      const formData = new FormData();
      formData.append("skillImage", imageList[addUpdateIndex[0]].file);
      axios
        .put(
          `${ATLAS_URI}/replaceUserSkillImage/${editUserId}/${putImageId}`,
          formData,
          configToken
        )
        .then((response) => {
          if (response.status === 200) {
            toast.success("Image Updated", {
              position: toast.POSITION.TOP_CENTER,
            });
          } else if (response.status === 400) {
            toast.error("Bad Request", { position: toast.POSITION.TOP_CENTER });
          }
          setPutImageId(0);
        })
        .catch((err) => {
          console.error(err);
          setPutImageId(0);
        });
    } else if (addImageId && editUserId) {
      // SAVE SKILL IMAGE
      const fm2 = new FormData();
      const newFile = imageList.filter((e) => !e.id);

      if (newFile && newFile.length) {
        fm2.append("skillImages", newFile[0].file);
        axios
          .post(`${ATLAS_URI}/skillImages/${editUserId}`, fm2, configToken)
          .then(() => {
            // {console.log('Number(e.target.parentElement.parentElement.id)', Number(e.target.parentElement.parentElement.id))}
          });
      }
    }
  };

  const [state, setState] = useState({
    resetNewRow: {
      s3KeyName: "",
      firstName: "",
      lastName: "",
      Username: "",
      Email: "",
      Mobile_number: "",
      Password: "",
      Role: "",
      coachCategory: "",
      shiftTimings: "",
      State: operator.Role === ADMIN ? "Approved" : "Pending",
      AssignedTo: operator.id,
      languageDisplayValue: [],
      medicalConditionDisplayValue: [],
      preferredCusineDisplayValue: [],
      departmentDisplayValue: "",
      reportingManagerDetail: "",
      // reportingManager:'',
      proficiencyDisplayValue: "",
      Location: "",
      getallLanguages: [],
      getallMedicalCondition: [],
      getallPreferredCusine: [],
      Language: "",
      MedicalCondition: "",
      PreferredCusine: "",
      imageUrl: "",
      about: "",
      status: false,
    },
    newTableRow: {
      s3KeyName: "",
      firstName: "",
      lastName: "",
      Username: "",
      Email: "",
      Mobile_number: "",
      Password: "",
      Role: "",
      coachCategory: "",
      shiftTimings: "",
      Roles: "",
      State: operator.Role === ADMIN ? "Approved" : "Pending",
      AssignedTo: operator.id,
      languageDisplayValue: [],
      medicalConditionDisplayValue: [],
      preferredCusineDisplayValue: [],
      departmentDisplayValue: "",
      reportingManager: "",
      proficiencyDisplayValue: "",
      Location: "",
      reportingManagerDetail: "",
      Language: "",
      imageUrl: "",
      about: "",
      status: 1,
    },
    getallLanguages: [],
    getallMedicalCondition: [],
    getallPreferredCusine: [],
    getDepartments: [],
    reportManager: [],
    proficiencyValue: [],
    SupportedUsers: "",
    CurrentUsers: "",
    tableBodyList: [],
    editingActivated: false,
    editingID: "",
    rolesList: [],
    APIs: {
      AddData: "addUser",
      UpdateData: "updateUser",
      DeleteData: "deleteUser",
      updateList: "usersList",
    },
    dialogInfo: {
      isOpened: false,
      text: "",
      type: "",
    },
  });

  const mounted = React.useRef(true);
  useEffect(
    () => () => {
      mounted.current = false;
    },
    []
  );

  useEffect(() => {
    axios
      .all([
        axios.get(`${ATLAS_URI}/getConfiguration/`, configToken),
        axios.get(`${ATLAS_URI}/getUsers/`, configToken),
        axios.get(`${ATLAS_URI}/getRoles/`, configToken),
      ])
      .then(
        axios.spread((configuration, users, role) => {
          const usersData = users.data;
          const configData = configuration.data[0];
          const rolesData = role.data;

          const newUsersData = usersData.map((user) => {
            const temp = rolesData.filter((role) => role.id === user.Role);
            if (temp.length !== 0) {
              user.Roles = temp[0].Role;
            } else {
              user.Role = "";
              user.Roles = "";
            }
            return user;
          });

          mounted.current &&
            setState((prevState) => ({
              ...prevState,
              SupportedUsers: Number(configData?.SupportedUsers),
              CurrentUsers: usersData.length,
              newTableRow: state.resetNewRow,
              tableBodyList: newUsersData,
              rolesList: rolesData,
            }));

          // console.log(newUsersData,'4521');
        })
      );
  }, []);

  //Overloading insertIntoTable
  function insertIntoTable(e) {
    e.preventDefault();
    const passwordEntered = $("#newPassword").val();
    if (!state.editingActivated) {
      // if (state.SupportedUsers > state.CurrentUsers) {

      //When Adding new Data
      let newUser = state.newTableRow;
      newUser.Password = md5(passwordEntered).substring(5, 25);
      axios
        .post(`${ATLAS_URI}/${state.APIs.AddData}/`, newUser, configToken)
        .then((response) => {
          if (response.status === 200) {
            let newTableBodyList = [...state.tableBodyList];

            let addedData = response.data;
            const temp = state.rolesList.filter(
              (role) => role.id.toString() === newUser.Role.toString()
            );
            if (temp.length !== 0) {
              addedData.Roles = temp[0].Role;
            } else {
              addedData.Role = "";
              addedData.Roles = "";
            }

            newTableBodyList.push(addedData);
            let curr = state.CurrentUsers + 1;
            setState((prevState) => ({
              ...prevState,
              CurrentUsers: curr,
              tableBodyList: newTableBodyList,
              newTableRow: state.resetNewRow,
            }));
            $("#newPassword").val("");
          }
        })
        .catch((err) => {});
      // }
      // else {
      //     const newDialogInfo = { isOpened: true, text: "Users Limit Reached", type: "Error" }
      //     setState(prevState => ({ ...prevState, dialogInfo: newDialogInfo }))
      //     setTimeout(() => { setState(prevState => ({ ...prevState, dialogInfo: { isOpened: false, text: "", type: "" } })) }, 3000)
      // }
    } else {
      //When Edit is Activated
      let editedUser = state.newTableRow;
      if (passwordEntered !== "") {
        editedUser.Password = md5(passwordEntered).substring(5, 25);
      }

      axios
        .post(
          `${ATLAS_URI}/${state.APIs.UpdateData}/` + state.editingID,
          editedUser,
          configToken
        )
        .then(() => {
          let addedData = editedUser;
          const temp = state.rolesList.filter(
            (role) => role.id.toString() === editedUser.Role
          );
          if (temp.length !== 0) {
            addedData.Roles = temp[0].Role;
          } else {
            addedData.Role = "";
            addedData.Roles = "";
          }
          const newTableBodyList = state.tableBodyList.map((data) =>
            data.id.toString() === state.editingID.toString() ? addedData : data
          );

          setState((prevState) => ({
            ...prevState,
            editingActivated: false,
            tableBodyList: newTableBodyList,
            newTableRow: state.resetNewRow,
          }));
          $("#newPassword").val("");
        })
        .catch((err) => console.log(err));
    }
  }

  function deleteFromTable(e) {
    e.preventDefault();
    const delID = state.dialogInfo.delID;
    axios
      .delete(`${ATLAS_URI}/${state.APIs.DeleteData}/` + delID, configToken)
      .then(() => {
        const newTableBodyList = state.tableBodyList.filter(
          (data) => data.id.toString() !== delID
        );

        setState((prevState) => ({
          ...prevState,
          CurrentUsers: state.CurrentUsers - 1,
          tableBodyList: newTableBodyList,
          tableBodyDisplayList: newTableBodyList,
          newTableRow: state.resetNewRow,
          dialogInfo: { isOpened: false, text: "", delID: "" },
        }));
      })
      .catch((err) => console.log(err));
  }

  function changeHandler(e) {
    oldChangeHandler(e, state, setState);
  }
  //    console.log(state.tableBodyList.map((c)=> c.Name),'4448');
  const userName = state.tableBodyList?.map((c) =>
    c.Name ? c.Name : `${c.firstName} ${c.lastName}`
  );

  const onSubmit = (e) => {
    console.log(state.editingActivated, "testtttt edi tuser");

    const passwordEntered = $("#newPassword").val();
    if (!state.editingActivated) {
      // if (state.SupportedUsers > state.CurrentUsers) {
      //    alert('Hi...')
      //When Adding new Data
      let newUser = {
        ...formik.values,
        Language: JSON.stringify(formik.values.languageDisplayValue),
        Department: formik.values.departmentDisplayValue,
        ProficiencyLevel: formik.values.proficiencyDisplayValue,
        reportManager: state.reportManager,
        medical_condition: JSON.stringify(
          formik.values.medicalConditionDisplayValue
        ),
        preferred_cuisine: JSON.stringify(
          formik.values.preferredCusineDisplayValue
        ),
      };

      newUser.Password = md5(passwordEntered).substring(5, 25);
      const formData = new FormData();

      if (file && file.type != "image/png" && file.type != "image/jpeg") {
        toast.warning("Only png/jpeg file is accepted", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      for (const i in newUser) {
        formData.append(i, newUser[i]);
      }
      if (file !== undefined) {
        formData.append("ProfilePic", file);
      }

      if (images.length !== 0) {
        let imageList = images.map((item) => item.file);
        formData.append("skillImages", imageList);
      }

      axios
        .post(`${ATLAS_URI}/${state.APIs.AddData}`, formData, configToken)
        .then((response) => {
          // alert('bye')
          if (response.status === 200) {
            let newTableBodyList = [...state.tableBodyList];

            let addedData = response.data;

            //SAVE SKILL IMAGES

            if (images.length !== 0) {
              let imageList = images.map((item) => item.file);
              const fm2 = new FormData();
              for (let i in imageList) {
                fm2.append(`skillImages`, imageList[i]);
              }

              axios
                .post(
                  `${ATLAS_URI}/skillImages/${addedData.id}`,
                  fm2,
                  configToken
                )
                .then(() => {});
            }

            const temp = state.rolesList.filter(
              (role) => role.id.toString() === newUser.Role.toString()
            );
            if (temp.length !== 0) {
              addedData.Roles = temp[0].Role;
            } else {
              addedData.Role = "";
              addedData.Roles = "";
            }

            newTableBodyList.push(addedData);
            let curr = state.CurrentUsers + 1;
            setState((prevState) => ({
              ...prevState,
              CurrentUsers: curr,
              tableBodyList: newTableBodyList,
              newTableRow: state.resetNewRow,
            }));
            $("#newPassword").val("");
            setImages([]);
            toast.success("Added successfully", {
              position: toast.POSITION.TOP_CENTER,
            });
            formik.resetForm();
            profileImageInputRef.current.value = "";
          }
        })
        .catch((err) => {
          // alert(err)
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
      // }
      //  else {
      //     const newDialogInfo = { isOpened: true, text: "Users Limit Reached", type: "Error" }
      //     setState(prevState => ({ ...prevState, dialogInfo: newDialogInfo }))
      //     setTimeout(() => { setState(prevState => ({ ...prevState, dialogInfo: { isOpened: false, text: "", type: "" } })) }, 3000)
      // }
    } else {
      //When Edit is Activated
      // let editedUser = formik.values;
      let editedUser = {
        ...formik.values,
        Language: JSON.stringify(formik.values.languageDisplayValue),
        medical_condition: JSON.stringify(
          formik.values.medicalConditionDisplayValue
        ),
        preferred_cuisine: JSON.stringify(
          formik.values.preferredCusineDisplayValue
        ),
        Department: formik.values.departmentDisplayValue,
        ProficiencyLevel: formik.values.proficiencyDisplayValue,
        reportManager: state.reportManager,
        Reporting_Manager_ID: formik.values.reportingManagerDetail
          ? formik.values.reportingManagerDetail
          : undefined,
      };

      if (passwordEntered !== "") {
        editedUser.Password = md5(passwordEntered).substring(5, 25);
      }

      const formData = new FormData();

      for (const i in editedUser) {
        if (editedUser[i] != undefined) formData.append(i, editedUser[i]);
      }

      if (file && file.type != "image/png" && file.type != "image/jpeg") {
        toast.warning("Only png/jpeg file is accepted", {
          position: toast.POSITION.TOP_CENTER,
        });
        return;
      }

      if (file !== undefined) {
        formData.append("ProfilePic", file);
      }

      axios
        .post(
          `${ATLAS_URI}/${state.APIs.UpdateData}/${editUserId}`,
          formData,
          configToken
        )
        .then(() => {
          setImages([]);
          let addedData = editedUser;
          const temp = state.rolesList.filter(
            (role) => role.id.toString() == editedUser.Role
          );
          if (temp.length !== 0) {
            addedData.Roles = temp[0].Role;
          } else {
            addedData.Role = "";
            addedData.Roles = "";
          }
          const newTableBodyList = state.tableBodyList.map((data) =>
            data.id.toString() === state.editingID.toString() ? addedData : data
          );

          setState((prevState) => ({
            ...prevState,
            editingActivated: false,
            tableBodyList: newTableBodyList,
            newTableRow: state.resetNewRow,
          }));
          $("#newPassword").val("");

          profileImageInputRef.current.value = "";
          toast.success("Updated successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
        })
        .catch((err) => console.log(err));
    }
  };

  {
    console.log(state, "testtt");
  }
  const formik = useFormik({
    initialValues: { ...state.newTableRow },
    onSubmit,
    validationSchema: addUserSchema,
    enableReinitialize: true,
  });

  /* Get All Languages,Department,ReportingManager,Proficiency */

  const [roleId, setRoleId] = useState();
  useEffect(() => {
    axios
      .all([
        axios.get(`${ATLAS_URI}/getLanguage`, configToken),
        axios.get(`${ATLAS_URI}/getDepartment`, configToken),
        axios.get(`${ATLAS_URI}/getUsers`, configToken),
        axios.get(`${ATLAS_URI}/getProficiencyLevel`, configToken),
        axios.get(`${ATLAS_URI}/getMedicalConditionMaster`, configToken),
        axios.get(`${ATLAS_URI}/getDietPreferenceMaster`, configToken),
      ])
      .then(
        axios.spread(
          (
            language,
            department,
            reportingManager,
            proficiency,
            medicalCondition,
            preferredCusine
          ) => {
            // console.log('reportingManager.data::::', reportingManager.data)
            // console.log(
            //   medicalCondition.data.body.medical_condition,
            //   preferredCusine.data.body.cuisine_preferred,
            //   "dataxxxxxxx"
            // );
            setState((prevState) => ({
              ...prevState,
              getallLanguages: language.data,
              getDepartments: department.data,
              getallMedicalCondition:
                medicalCondition.data.body.medical_condition,
              getallPreferredCusine:
                preferredCusine.data.body.cuisine_preferred,
              //  reportManager: reportingManager.data.map(c=>{c.reportingManagerDetail=c?.firstName; return c}),
              // reportManager:[],
              proficiencyValue: proficiency.data,
            }));
          }
        )
      );
  }, []);

  function getReportingUsers(roleId) {
    setRoleId(roleId);
    axios
      .get(`${ATLAS_URI}/getRoleByID/${roleId}`, configToken)
      .then((res) => {
        if (res.status == 200) {
          setRole(res.data);

          axios
            .get(
              `${ATLAS_URI}/getReportingUserList?roleName=${res.data.Role}`,
              configToken
            )
            .then((userRes) => {
              if (userRes.status == 200) {
                setState((prevState) => ({
                  ...prevState,
                  reportManager:
                    userRes.data.length > 0
                      ? userRes.data.map((userInfo) => {
                          console.log("userInfo::::", userInfo);
                          // return `${userInfo?.firstName} ${userInfo?.lastName ? userInfo?.lastName : ''}`
                          userInfo.reportingManagerDetail = `${
                            userInfo?.firstName
                          } ${userInfo?.lastName ? userInfo?.lastName : ""}`
                            ? `${userInfo?.firstName} ${
                                userInfo?.lastName ? userInfo?.lastName : ""
                              }`
                            : undefined;

                          return userInfo;
                        })
                      : [],
                }));
              }
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  }
  // useEffect(() => {
  //     getReportingUsers()
  // }, [roleId])

  /* Edit User Row */
  const [editRole, setEditRole] = useState();
  const [editUserId, setEditUserId] = useState();
  const editUserRow = (e) => {
    setEditUserId(Number(e.target.parentElement.parentElement.id));
    axios
      .get(
        `${ATLAS_URI}/getUserByID/${Number(
          e.target.parentElement.parentElement.id
        )}`,
        configToken
      )
      .then(
        (res) => (
          // setEditRole(state.reportManager.length>0 ? res?.data?.reportingManagerInfo?.id:''),
          // $("#newPassword").val(""),

          setState((prevState) => {
            const parseJSON = (data) => {
              try {
                return JSON.parse(data);
              } catch {
                return [];
              }
            };

            return {
              ...prevState,
              editingActivated: true,
              newTableRow: {
                languageDisplayValue: res?.data?.Language
                  ? res?.data?.Language
                  : [],
                medicalConditionDisplayValue: parseJSON(
                  res.data.medical_condition
                ),
                preferredCusineDisplayValue: parseJSON(
                  res.data.preferred_cuisine
                ),
                departmentDisplayValue: res?.data?.Departments?.id
                  ? res?.data?.Departments?.id
                  : "",
                reportingManagerDetail: res?.data?.reportingManagerInfo?.id
                  ? res?.data?.reportingManagerInfo?.id
                  : "",
                proficiencyDisplayValue: res?.data?.ProficiencyLevels?.id
                  ? res?.data?.ProficiencyLevels?.id
                  : "",
                firstName: res.data.firstName,
                lastName: res.data.lastName,
                Username: res.data.Username,
                Email: res.data.Email,
                Mobile_number: res.data.Mobile_number,
                Location: res.data.Location,
                Role: res.data.Role,
                editingID: Number(e.target.parentElement.parentElement.id),
                imageUrl: res?.data?.fileURL,
                about: res.data.about ? res.data.about : "",
                status: res.data.status,
                s3KeyName: res.data.s3KeyName,
                coachCategory: res.data.coachCategory,
                shiftTimings: res.data.shiftTimings,

                // Password:$("#newPassword").val("")
              },
            };
          }),
          setImages(res.data.skillImages),
          getReportingUsers(res.data.Role)
        )
      )
      .catch((err) => console.log(err));
  };

  // console.log(state, "checkinggg");

  // useEffect(() => {
  //     if(editUserId){

  //         editUserRow()
  //     }
  // }, [])

  const profilePic = () => {
    setShowreportManager(true);
  };

  const togglePwd = () => {
    setShow(!show);
  };

  const handleChange = (e) => {
    setFile(e.target.files[0]);
  };

  const deleteImage = (imageDtls) => {
    if (imageDtls !== null && imageDtls.id !== undefined && !!editUserId) {
      axiosFetch({
        url: `/deleteUserSkillImage/${editUserId}/${imageDtls.id}`,
        method: "delete",
      })
        .then((res) => {
          if (res.status === 200) {
            toast.success("Removed Image", {
              position: toast.POSITION.TOP_CENTER,
            });
          } else if (res.status === 400) {
            toast.error("Bad Request", { position: toast.POSITION.TOP_CENTER });
          } else if (res.status === 401) {
            history("/");
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const [putImageId, setPutImageId] = useState(0);
  const [addImageId, setAddImageId] = useState(false);

  const updateImage = (imageDtls) => {
    if (imageDtls && imageDtls.id) {
      setPutImageId(imageDtls.id);
      setAddImageId(false);
    }
  };

  const handleNewImage = (e, cb) => {
    setAddImageId(true);
    cb();
  };

  const profileImageInputRef = React.useRef(null);

  return (
    <section className="content">
      <div className="row">
        {typeof state.dialogInfo !== "undefined" && (
          <Dialog
            // onFalse={(e) => setState(...state, { dialogInfo: { isOpened: false, text: "" } })}
            onFalse={(e) =>
              setState((prevState) => ({
                ...prevState,
                dialogInfo: { isOpened: false, text: "" },
              }))
            }
            onTrue={deleteFromTable}
            dialogInfo={state.dialogInfo}
          />
        )}
        <div className="col-md-5">
          <div className="box box-primary">
            <BoxHeader
              title={`${state.editingActivated ? "Edit" : "Add"} User`}
            />

            <form onSubmit={formik.handleSubmit} autoComplete="off">
              <div className="box-body bozero">
                <input type="hidden" name="ci_csrf_token" value="" />

                {/* <div className="form-group">
                                    <label>Name </label><small className="req"> *</small>
                                    <input name="Name" value={formik.values.Name} onChange={formik.handleChange} onBlur={formik.handleBlur} type="text" className={formik.errors.Name && formik.touched.Name?'form-control input-error':'form-control'} />
                                </div>
                                {formik.errors.Name && formik.touched.Name && <p className="error-message">{formik.errors.Name}</p>} */}

                <div className="row topProfile">
                  <div className="col-md-2 profile-image">
                    {state.newTableRow.imageUrl && (
                      <img src={state.newTableRow.imageUrl} alt="profile" />
                    )}
                    <input
                      type="file"
                      ref={profileImageInputRef}
                      name="ProfilePic"
                      onChange={handleChange}
                      accept="image/png, image/jpeg"
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label>First Name </label>
                  <small className="req"> *</small>
                  <input
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="text"
                    className={
                      formik.errors.firstName && formik.touched.firstName
                        ? "form-control input-error"
                        : "form-control"
                    }
                  />
                </div>
                {formik.errors.firstName && formik.touched.firstName && (
                  <p className="error-message">{formik.errors.firstName}</p>
                )}

                <div className="form-group">
                  <label>Last Name </label>
                  <small className="req"> *</small>
                  <input
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="text"
                    className={
                      formik.errors.lastName && formik.touched.lastName
                        ? "form-control input-error"
                        : "form-control"
                    }
                  />
                </div>
                {formik.errors.lastName && formik.touched.lastName && (
                  <p className="error-message">{formik.errors.lastName}</p>
                )}

                <div className="form-group">
                  <label>Username </label>
                  <small className="req"> *</small>
                  <input
                    name="Username"
                    value={formik.values.Username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="text"
                    className={
                      formik.errors.Username && formik.touched.Username
                        ? "form-control input-error"
                        : "form-control"
                    }
                  />
                </div>
                {formik.errors.Username && formik.touched.Username && (
                  <p className="error-message">{formik.errors.Username}</p>
                )}

                <div className="form-group">
                  <label>Email </label>
                  <small> (optional)</small>
                  <input
                    name="Email"
                    value={formik.values.Email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="text"
                    className={
                      formik.errors.Email && formik.touched.Email
                        ? "form-control input-error"
                        : "form-control"
                    }
                  />
                </div>
                {formik.errors.Email && formik.touched.Email && (
                  <p className="error-message">{formik.errors.Email}</p>
                )}

                <div className="form-group">
                  <label>Mobile No. </label>
                  <small> (optional)</small>
                  <PhoneInput
                    value={formik.values.Mobile_number}
                    onChange={(e) => {
                      formik.setFieldValue("Mobile_number", e && "+" + e);
                    }}
                    country={"in"}
                    enableSearch={true}
                    inputStyle={
                      formik.errors.Mobile_number &&
                      formik.touched.Mobile_number
                        ? {
                            paddingLeft: "5%",
                            backgroundColor: "#fce4e4",
                            borderColor: "#ff01416a",
                            width: "100%",
                          }
                        : { paddingLeft: "5%", width: "100%" }
                    }
                  />
                </div>
                {formik.errors.Mobile_number &&
                  formik.touched.Mobile_number && (
                    <p className="error-message">
                      {formik.errors.Mobile_number}
                    </p>
                  )}

                {/*  Addition -Abhishek  Start */}

                {/* <div className='form-group'> */}

                {/* <label>Language</label><br/>
                                <select>
                                    <option>English</option>
                                    <option>Kannada</option>
                                    <option>Tamil</option>
                                    <option>Malayalam</option>
                                </select> */}
                {/* 
                <SelectBox
                  label="Language"
                  name="languageDisplayValue"
                  isEdit={true}
                  options={state.getallLanguages}
                  attributeShown="languageDisplayValue"
                  formikdata={formik.errors.languageDisplayValue}
                  formikTouched={formik.touched.languageDisplayValue}
                  changeHandler={formik.handleChange}
                  value={formik.values.languageDisplayValue}
                  resetValue={() =>
                    setState((prevState) => ({
                      newTableRow: {
                        ...prevState.newTableRow,
                        languageDisplayValue: "Select",
                      },
                    }))
                  }
                />
                {formik.errors.languageDisplayValue &&
                  formik.touched.languageDisplayValue && (
                    <p className="error-message">
                      {formik.errors.languageDisplayValue}
                    </p>
                  )} */}

                <Typography> Language </Typography>
                <Autocomplete
                  size="small"
                  multiple // Enable multiple selection
                  options={state.getallLanguages} // Array of language options
                  getOptionLabel={(option) =>
                    option ? option.languageDisplayValue : []
                  }
                  value={
                    formik.values.languageDisplayValue.map((val) =>
                      state.getallLanguages.find((option) => option.id === val)
                    ) || []
                  } // Current values (map selected values to their corresponding options)
                  onChange={(event, newValue) => {
                    formik.setFieldValue(
                      "languageDisplayValue",
                      newValue.map((item) => item.id) // Store selected options' values
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="languageDisplayValue"
                      error={formik.values.languageDisplayValue.length === 0} // Check if value is empty
                      helperText={
                        formik.values.languageDisplayValue.length === 0
                          ? "Language is required."
                          : ""
                      }
                    />
                  )}
                />

                <br />

                <Typography> Medical Condition </Typography>
                <Autocomplete
                  size="small"
                  multiple // Enable multiple selection
                  options={state.getallMedicalCondition}
                  value={formik.values.medicalConditionDisplayValue} // Use the selected values directly
                  onChange={(event, newValue) => {
                    formik.setFieldValue(
                      "medicalConditionDisplayValue",
                      newValue
                    ); // Store selected options' values directly
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="medicalConditionDisplayValue"
                    />
                  )}
                />

                <br />

                <Typography> Preferred Cuisine </Typography>
                <Autocomplete
                  size="small"
                  multiple // Enable multiple selection
                  options={state.getallPreferredCusine}
                  value={formik.values.preferredCusineDisplayValue} // Use the selected values directly
                  onChange={(event, newValue) => {
                    formik.setFieldValue(
                      "preferredCusineDisplayValue",
                      newValue
                    ); // Store selected options' values directly
                  }}
                  renderInput={(params) => (
                    <TextField {...params} name="preferredCusineDisplayValue" />
                  )}
                />

                <br />

                <SelectBox
                  label="Department"
                  name="departmentDisplayValue"
                  isEdit={true}
                  options={state.getDepartments}
                  attributeShown="departmentDisplayValue"
                  formikdata={formik.errors.departmentDisplayValue}
                  formikTouched={formik.touched.departmentDisplayValue}
                  changeHandler={formik.handleChange}
                  value={formik.values.departmentDisplayValue}
                  resetValue={() =>
                    setState((prevState) => ({
                      newTableRow: {
                        ...prevState.newTableRow,
                        departmentDisplayValue: "Select",
                      },
                    }))
                  }
                />
                {formik.errors.departmentDisplayValue &&
                  formik.touched.departmentDisplayValue && (
                    <p className="error-message">
                      {formik.errors.departmentDisplayValue}
                    </p>
                  )}
                <br />

                <SelectBox
                  label="Proficiency Level"
                  name="proficiencyDisplayValue"
                  isEdit={true}
                  options={state.proficiencyValue}
                  attributeShown="proficiencyDisplayValue"
                  formikdata={formik.errors.proficiencyDisplayValue}
                  formikTouched={formik.touched.proficiencyDisplayValue}
                  changeHandler={formik.handleChange}
                  value={formik.values.proficiencyDisplayValue}
                  resetValue={() =>
                    setState((prevState) => ({
                      newTableRow: {
                        ...prevState.newTableRow,
                        proficiencyDisplayValue: "Select",
                      },
                    }))
                  }
                />
                <span style={{ color: "red" }}>*</span>
                {formik.errors.proficiencyDisplayValue &&
                  formik.touched.proficiencyDisplayValue && (
                    <p className="error-message">
                      {formik.errors.proficiencyDisplayValue}
                    </p>
                  )}
                <br />
                {/* </div> */}

                <div className="form-group">
                  <label>Location </label>
                  <input
                    name="Location"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="text"
                    className={
                      formik.errors.Location && formik.touched.Location
                        ? "form-control input-error"
                        : "form-control"
                    }
                    value={formik.values.Location}
                    placeholder="India or NRI"
                  />
                </div>

                {/* END */}

                <div className="form-group">
                  <label>{state.editingActivated && "Change "}Password </label>{" "}
                  {!state.editingActivated && <small className="req"> *</small>}
                  <input
                    name="Password"
                    id="newPassword"
                    type={show ? "text" : "password"}
                    className={
                      formik.errors.Password && formik.touched.Password
                        ? "form-control input-error"
                        : "form-control"
                    }
                    value={formik.values.Password}
                    onChange={formik.handleChange}
                    required={!state.editingActivated}
                    placeholder={
                      state.editingActivated
                        ? "Leave this field blank to keep Password same"
                        : undefined
                    }
                  />
                  {show ? (
                    <VisibilityIcon
                      onClick={togglePwd}
                      style={{
                        transform: "translate(-16px, -27px)",
                        float: "right",
                      }}
                    />
                  ) : (
                    <VisibilityOffIcon
                      onClick={togglePwd}
                      style={{
                        transform: "translate(-16px, -27px)",
                        float: "right",
                      }}
                    />
                  )}
                </div>
                {formik.errors.Password && formik.touched.Password && (
                  <p className="error-message">{formik.errors.Password}</p>
                )}

                {/* <SelectBox
                                    label="Role"
                                    name="Role"
                                    isEdit={true}
                                    options={state.rolesList}
                                    attributeShown="Role"
                                    formikdata={formik.errors.Role}
                                    formikTouched={formik.touched.Role}
                                    changeHandler={formik.handleChange}
                                    value={formik.values.Role}
                                    resetValue={() => setState(prevState => ({ newTableRow: { ...prevState.newTableRow, Role: "Select" } }))}
                                />
                                {formik.errors.Role && formik.touched.Role && <p className="error-message">{formik.errors.Role}</p>} */}

                <label>Role</label>
                <select
                  className={
                    formik.errors.Role && formik.touched.Role
                      ? "form-control input-error"
                      : "form-control"
                  }
                  name="Role"
                  onBlur={formik.handleBlur}
                  value={formik.values.Role}
                  onChange={(e) => {
                    formik.setFieldValue("Role", e.target.value);

                    getReportingUsers(e.target.value);
                  }}
                >
                  <option value="">Select</option>
                  {state.rolesList?.map((role) => (
                    <option value={role.id}>{role.Role}</option>
                  ))}
                </select>

                {formik.errors.Role && formik.touched.Role && (
                  <p className="error-message">{formik.errors.Role}</p>
                )}

                <br />

                {/*  ****************** New Field */}

                <label>Coach Category</label>
                <select
                  className={
                    formik.errors.coachCategory && formik.touched.Role
                      ? "form-control input-error"
                      : "form-control"
                  }
                  name="coachCategory"
                  onBlur={formik.handleBlur}
                  value={formik.values.coachCategory}
                  onChange={(e) => {
                    formik.setFieldValue("coachCategory", e.target.value);
                  }}
                >
                  <option value="">Select</option>
                  {/* {state.rolesList?.map((role) => (
                    <option value={role.id}>{role.Role}</option>
                  ))} */}
                  <option value={"Gold"}>Gold</option>
                  <option value={"Platinum"}>Platinum</option>
                </select>

                {formik.errors.coachCategory &&
                  formik.touched.coachCategory && (
                    <p className="error-message">
                      {formik.errors.coachCategory}
                    </p>
                  )}
                <br />
                <label>Shift Timings</label>
                <select
                  className={
                    formik.errors.shiftTimings && formik.touched.shiftTimings
                      ? "form-control input-error"
                      : "form-control"
                  }
                  name="shiftTimings"
                  onBlur={formik.handleBlur}
                  value={formik.values.shiftTimings}
                  onChange={(e) => {
                    formik.setFieldValue("shiftTimings", e.target.value);
                  }}
                >
                  <option value="">Select</option>
                  <option value={"6 am to 2 pm"}>6 am to 2 pm</option>
                  <option value={"10 am to 6 pm"}>10 am to 6 pm</option>
                  <option value="2 pm to 10 pm">2 pm to 10 pm</option>
                  <option value="6 pm to 2 am">6 pm to 2 am</option>
                </select>

                {formik.errors.shiftTimings && formik.touched.shiftTimings && (
                  <p className="error-message">{formik.errors.shiftTimings}</p>
                )}

                <br />

                {/*  ****************** New Field */}

                {/* {
                                    state.reportManager?.length > 0 && <SelectBox
                                    label="Reporting Manager"
                                    name="reportingManagerDetail"
                                    isEdit={true}
                                    options={state.reportManager}
                                    attributeShown='reportingManagerDetail'
                                    formikdata={formik.errors.reportingManagerDetail}
                                    formikTouched={formik.touched.reportingManagerDetail}
                                    changeHandler={formik.handleChange}
                                    value={formik.values.reportingManagerDetail}
                                    resetValue={() => setState(prevState => ({ newTableRow: { ...prevState.newTableRow, reportingManagerDetail: "Select" } }))}
                                />
} */}

                {/* {formik.errors.reportingManagerDetail && formik.touched.reportingManagerDetail && <p className="error-message">{formik.errors.reportingManagerDetail}</p>}  */}
                {state.reportManager?.length > 0 && (
                  <>
                    {" "}
                    <label>Report Manager</label>
                    <select
                      className={
                        formik.errors.reportingManagerDetail &&
                        formik.touched.reportingManagerDetail
                          ? "form-control input-error"
                          : "form-control"
                      }
                      name="reportingManagerDetail"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.reportingManagerDetail}
                    >
                      <option value="">Select</option>
                      {state.reportManager?.map((data) => (
                        <option value={data.id}>
                          {data.reportingManagerDetail}
                        </option>
                      ))}
                    </select>
                  </>
                )}

                <br />

                <div className="form-group">
                  <label>About Me </label>
                  <textarea
                    name="about"
                    value={formik.values.about}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="text"
                    className={
                      formik.errors.about && formik.touched.about
                        ? "form-control input-error"
                        : "form-control"
                    }
                  />
                </div>
                {formik.errors.about && formik.touched.about && (
                  <p className="error-message">{formik.errors.about}</p>
                )}

                <br />

                <div className="form-group">
                  <FormLabel id="demo-radio-buttons-group-label">
                    Live Session Coach
                  </FormLabel>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formik.values.status}
                        onChange={formik.handleChange}
                        name="status"
                        color="primary"
                      />
                    }
                  />

                  {/* <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="1"
                                        name="status"
                                        className="d-flex"
                                        value={formik.values.status}
                                    >
                                        <div className="form-check form-check-inline">
                                            <FormControlLabel value="1" control={<Radio onChange={formik.handleChange} />} label="Active" />
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <FormControlLabel value="0" control={<Radio onChange={formik.handleChange} />} label="Inactive" />
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <FormControlLabel value="2" control={<Radio onChange={formik.handleChange} />} label="Deleted" />
                                        </div>
                                    </RadioGroup> */}
                </div>

                <div className="form-group">
                  <label>Upload Skill Pictures</label>
                  <ImageUploading
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageRemoveAll,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                      errors,
                    }) => (
                      // write your building UI
                      <div className="upload__image-wrapper">
                        {imageList.length < 3 && (
                          <Button
                            variant="outlined"
                            style={isDragging ? { color: "red" } : undefined}
                            onClick={(e) => handleNewImage(e, onImageUpload)}
                            {...dragProps}
                            startIcon={<UploadFileIcon />}
                            onChange={(e) =>
                              console.log("file", e.target.files)
                            }
                            className="my-5"
                          >
                            Click or Drop here
                          </Button>
                        )}

                        {/* &nbsp;
                                                {imageList.length !== 0 &&  <Button onClick={onImageRemoveAll} variant='outlined' startIcon={<HighlightOffIcon/>} color='error'>Remove all images</Button>}
                                                <br/><br/> */}
                        {imageList.map((image, index) => (
                          <Row
                            key={index}
                            className="image-item"
                            style={{ minHeight: "100px" }}
                          >
                            <Col md={4}>
                              <img src={image["data_url"]} alt="" width="100" />
                            </Col>
                            <Col md={8}>
                              <div className="image-item__btn-wrapper">
                                <Stack direction="row" spacing={2}>
                                  <Button
                                    variant="outlined"
                                    onClick={() => {
                                      onImageUpdate(index, image["id"]);
                                      updateImage(image);
                                    }}
                                    startIcon={<BrowserUpdatedIcon />}
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    onClick={() => {
                                      onImageRemove(index);
                                      deleteImage(image);
                                    }}
                                    startIcon={<RemoveCircleOutlineIcon />}
                                    color="error"
                                  >
                                    Remove
                                  </Button>
                                </Stack>
                              </div>
                            </Col>
                          </Row>
                        ))}

                        {errors && (
                          <div>
                            {errors.maxNumber && (
                              <span style={{ color: "red" }}>
                                You have reached the maximum no of images to
                                upload
                              </span>
                            )}
                            {errors.acceptType && (
                              <span style={{ color: "red" }}>
                                Your selected file type is not allow
                              </span>
                            )}
                            {errors.maxFileSize && (
                              <span style={{ color: "red" }}>
                                Selected file size exceed maxFileSize
                              </span>
                            )}
                            {errors.resolution && (
                              <span style={{ color: "red" }}>
                                Selected file is not match your desired
                                resolution
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </ImageUploading>
                </div>
              </div>

              <div className="box-footer">
                <button type="submit" className="btn btn-info pull-right ">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="col-md-7">
          <div className="box box-primary">
            <BoxHeader title="List of Added Users" />

            <div className="box-body">
              <DataTable
                tableHeader={[
                  "id",
                  "firstName",
                  "Username",
                  "Password",
                  "Roles",
                ]}
                searchField="firstName"
                tableBody={state.tableBodyList}
                deleteFromTable={(e) => openDialog(e, state, setState)}
                // editTableRow={(e) => EditTableRow(e, state, setState)}
                editTableRow={(e) => editUserRow(e, state, setState)}
                userName={userName}
              />

              {/* <DataTable
                                tableHeader={["id", "Name", "Username", "Password", "Roles"]}
                                searchField="Name"
                                tableBody={state.tableBodyList}
                                /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ManageUsers;
