import React, { useEffect, useState } from "react";

import { Menu, MenuItem } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { axiosFetch } from "../../../../Utlis/AxiosFetch";
import DeleteModal from "./DeleteModal";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const ActionMenu = ({ anchorEl, onClose, currentRow }) => {
  const history = useHistory();
  const [isopen, setIsopen] = useState(false);

  const handleClick = (value) => {
    const updatedCurrentRow = { ...currentRow };

    updatedCurrentRow.groupOne = JSON.parse(updatedCurrentRow.groupOne);
    updatedCurrentRow.groupTwo = JSON.parse(updatedCurrentRow.groupTwo);
    updatedCurrentRow.groupThree = JSON.parse(updatedCurrentRow.groupThree);

    history.push({
      pathname: "/Settings/automation/addClientRule",
      state: {
        isEdit: value === "edit",
        isView: value === "view",
        currentRow: updatedCurrentRow,
      },
    });
  };

  const hadlePopup = async () => {
    setIsopen(true);
    onClose();
  };

  const handleClose = () => {
    setIsopen(false);
  };

  const handleDelete = async (id) => {
    try {
      const response = await axiosFetch({
        url: `/api/v1/clientAutomation/${currentRow.id}?clientType=${currentRow?.assignedTo}`,
        method: "delete",
      });
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setIsopen(false);
        onClose("delete", currentRow?.assignedTo);
      }
    } catch (error) {
      console.error("Error deleting task rule:", error);
    }
  };

  const handleUpdateStatus = async (value) => {
    try {
      const response = await axiosFetch({
        url: `/api/v1/clientAutomation/updateStatus`,
        method: "put",
        requestConfig: {
          id: currentRow.id,
          assignedTo: currentRow.assignedTo,
          status: value,
        },
      });
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        onClose("status", currentRow?.assignedTo);
      }
    } catch (error) {
      console.error("Error deleting task rule:", error);
    }
  };

  return (
    <div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        style={{
          top: "-100px",
          left: "-50px",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClick("edit");
          }}
        >
          <span className="mr-5">
            <EditIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
          </span>
          <span className="ms-1">Edit</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClick("view");
          }}
        >
          <span className="mr-5">
            <VisibilityIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
          </span>
          <span className="ms-1">View</span>
        </MenuItem>
        {currentRow.status && (
          <MenuItem
            onClick={() => {
              handleUpdateStatus(false);
            }}
          >
            <span className="mr-5">
              <CloseIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
            </span>
            <span className="ms-1">Inactive </span>
          </MenuItem>
        )}
        {!currentRow.status && (
          <MenuItem
            onClick={() => {
              handleUpdateStatus(true);
            }}
          >
            <span className="mr-5">
              <CheckIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
            </span>
            <span className="ms-1">Active</span>
          </MenuItem>
        )}
        <MenuItem onClick={hadlePopup}>
          <span className="mr-5">
            <DeleteIcon style={{ fontSize: "20px", color: "#6B6B6B" }} />
          </span>
          <span className="ms-1">Delete</span>
        </MenuItem>
      </Menu>

      <DeleteModal
        id={currentRow?.id}
        isOpen={isopen}
        onClose={handleClose}
        handleDelete={handleDelete}
      />
    </div>
  );
};

export default ActionMenu;
