import { makeStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Card,
  Button,
  Stack,
  Menu,
  MenuItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import AddNotes from "./AddNotes";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import IconButton from "@mui/material/IconButton";
import Edit from "../../../assets/Edit.png";
import Delete from "../../../assets/Delete.png";
import {
  useParams,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import { axiosFetch } from "../../../Utlis/AxiosFetch";
import { toast } from "react-toastify";
import moment from "moment";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      border: "1px solid #D0D0D0",
    },
  },
});

const NoteList = () => {
  const classes = useStyles();
  let params = useParams();
  let history = useHistory();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  let [note, setNote] = useState("");
  let [notesList, setNotesList] = useState([]);

  let [popup_name, setPopup_name] = useState("Add Note");
  const openMenu = Boolean(anchorEl);
  const openPopup = () => {
    setOpen(true);
    setPopup_name("Add Note");
    setNote("");
  };
  const closePopup = () => {
    setOpen(false);
  };

  const handleClick = (event, note) => {
    setAnchorEl(event.currentTarget);
    setNote(note);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getNotesList = () => {
    axiosFetch({
      url: `/getNoteByLeadId/${params.id}`,
      method: "get",
    })
      .then((res) => {
        if (res.status === 200) {
          setNotesList(res.data);
        } else if (res.status === 400) {
          toast.error("Bad Request", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 500) {
          toast.error("Internal Server Error", {
            position: toast.POSITION.TOP_CENTER,
          });
        } else if (res.status === 401) {
          history.push("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const editNote = () => {
    setOpen(true);
    setPopup_name("Edit Note");
    handleClose();
  };

  const deleteNote = () => {
    handleClose();
    axiosFetch({
      url: `/deleteNoteById/${note.id}`,
      method: "delete",
    }).then((res) => {
      if (res.status === 200) {
        toast.success("Note deleted!", {
          position: toast.POSITION.TOP_CENTER,
        });
        getNotesList();
      } else if (res.status === 400) {
        toast.error("Bad Request", {
          position: toast.POSITION.TOP_CENTER,
        });
      } else if (res.status === 401) {
        history.push("/");
      } else if (res.status === 500) {
        toast.error("Internal Server Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    });
  };

  const handleChangeNote = (notes) => {
    if (note !== "") {
      note.note = notes;
    }
  };
  useEffect(() => {
    getNotesList();
  }, []);
  return (
    <div>
      <Card sx={{ width: "1500px !important" }}>
        <div style={{ margin: "22px" }}>
          <br />
          <Stack direction="row" spacing={6}>
            <Typography
              sx={{
                color: "#2D2D2D",
                fontFamily: "Poppins",
                fontWeight: "600",
                Size: "18px",
              }}
            >
              Notes
            </Typography>
            <Button
              variant="contained"
              sx={{
                color: "white",
                backgroundColor: "black",
                textTransform: "capitalize",
                marginLeft: "89% !important",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
              }}
              onClick={openPopup}
            >
              Add Notes
            </Button>
          </Stack>
          <br />
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead sx={{ backgroundColor: "#F4F4F4" }}>
                <TableRow>
                  <TableCell align="left">
                    <b>S.No</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Role</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Notes</b>
                  </TableCell>
                  <TableCell>
                    <b>Created by</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Created Date</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Updated by</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Updated Date</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Action</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notesList.length !== 0 ? (
                  notesList.map((note, index) => (
                    <TableRow sx={{ color: "#6B6B6B" }} key={note.id}>
                      <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                        {index + 1}
                      </TableCell>
                      <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                        {note.Role !== null ? note?.Role : "-"}
                      </TableCell>
                      <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                        {note.note !== null ? note.note : "-"}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ color: "#6B6B6B" }}
                      >
                        {note.createdByInfo !== null ? note.createdByInfo : "-"}
                      </TableCell>
                      <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                        {/* {note.createdAt.split("T")[0]} */}
                        {note.createdAt
                            ? moment(note.createdAt).format("DD/MM/YYYY")
                            : "-"}
                      </TableCell>
                      <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                        {note.Lead.updated_by !== null
                          ? note.Lead.updated_by
                          : "-"}
                      </TableCell>
                      <TableCell align="left" sx={{ color: "#6B6B6B" }}>
                      {note.updatedAt
                            ? moment(note.updatedAt).format("DD/MM/YYYY")
                            : "-"}
                      </TableCell>
                      <TableCell align="left">
                        <IconButton
                          aria-label="action-button"
                          onClick={(e) => handleClick(e, note)}
                        >
                          <MoreHorizIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={8}
                      style={{ color: "red" }}
                      align="center"
                    >
                      No Results Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={editNote}>
              <img
                src={Edit}
                style={{
                  width: "20.12px",
                  height: "20.12px",
                  top: "1.88px",
                  left: "2px",
                  border: "2px",
                }}
              />
              Edit
            </MenuItem>
            <hr />
            <MenuItem onClick={deleteNote}>
              {" "}
              <img
                src={Delete}
                style={{
                  width: "20.12px",
                  height: "20.12px",
                  top: "1.88px",
                  left: "2px",
                  border: "2px",
                }}
              />
              Delete
            </MenuItem>
          </Menu>

          {open ? (
            <AddNotes
              close={closePopup}
              getNotesList={getNotesList}
              popup_name={popup_name}
              handleChangeNote={handleChangeNote}
              note={note}
            />
          ) : (
            ""
          )}
        </div>
      </Card>
    </div>
  );
};

export default NoteList;
