import { Button, Grid, Typography, Box } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ActionMenu from "./modals/ActionMenu";
import { useReducer } from "react";
import { utcFormater } from "../../../../hooks/helperFunction/dateConverter";
import moment from "moment";

const typographyStyles = {
  title: {
    fontSize: "18px",
    fontWeight: 600,
    textTransform: "capitalize",
    padding: "10px",
    paddingBottom: "0px",
    display: "flex",
    justifyContent: "space-between",
  },
  description: {
    fontSize: "16px",
    fontWeight: 500,
    textTransform: "capitalize",
    padding: "10px",
  },
  program: {
    fontSize: "16px",
    fontWeight: 500,
    textTransform: "capitalize",
    padding: "10px",
    display: "flex",
  },

  footer: {
    fontSize: "18px",
    fontWeight: 500,
    textTransform: "capitalize",
    marginBottom: "10px",
    padding: "10px",
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },

  footer_item: {
    fontSize: "18px",
    color: "#6B6B6B",
  },
  icon: {
    cursor: "pointer",
  },
};

const initialObject = {
  anchorEl: false,
  currentTask: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "action":
      return {
        ...state,
        anchorEl: action.payload.anchorEl,
        currentTask: action.payload.currentTask,
      };
    default: {
      throw new Error("Unkown Action");
    }
  }
};

const TaskItem = ({ task, id, getTaskDetails }) => {
  const [{ anchorEl, currentTask }, dispatch] = useReducer(
    reducer,
    initialObject
  );

  const handleClick = (e) => {
    dispatch({
      type: "action",
      payload: { anchorEl: e.target, currentTask: task },
    });
  };

  const handleClose = (value) => {
    dispatch({ type: "action", payload: { anchorEl: null } });
    if (value === "delete") {
      getTaskDetails();
    }
  };

  return (
    <Box
      sx={{
        marginBottom: "50px",
        // border: "2px solid #ddd",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        borderRadius: "6px",
      }}
    >
      <Box sx={{ p: "10px" }}>
        <Typography component="span" sx={typographyStyles.title}>
          {id + 1} {task.ruleName}
          <MoreVertIcon sx={typographyStyles.icon} onClick={handleClick} />
        </Typography>
        <Typography sx={typographyStyles.description}>
          {task.description}
        </Typography>
        <Typography component="div" sx={typographyStyles.program}>
          <Typography component="span" sx={typographyStyles.title}>
            Programs:
          </Typography>
          <Box
            sx={{
              width: "100%",
              overflowX: "auto",
              display: "flex",
            }}
          >
            {task?.programList?.map((program, index) => (
              <Typography
                component="span"
                key={index}
                sx={typographyStyles.program}
              >
                {program.name}
                {index !== task?.programList?.length - 1 ? "," : ""}
              </Typography>
            ))}
          </Box>
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderTop: "2px solid #ddd",
        }}
      >
        <Box
          sx={{
            width: "100%",
            // borderRight: "2px solid #ddd"
            p: "10px",
          }}
        >
          <Typography component="span" sx={typographyStyles.footer}>
            <Typography component="span" sx={typographyStyles.footer_item}>
              Created:
            </Typography>
            {task.createdBy} {utcFormater(task.createdAt)[0]}
          </Typography>
        </Box>
        <Box sx={{ width: "100%", p: "10px" }}>
          <Typography component="span" sx={typographyStyles.footer}>
            <Typography component="span" sx={typographyStyles.footer_item}>
              Updated:
            </Typography>
            {task.updatedBy} {utcFormater(task.updatedAt)[0]}
          </Typography>
        </Box>
      </Box>
      <ActionMenu
        anchorEl={anchorEl}
        onClose={handleClose}
        currentRow={currentTask}
      />
    </Box>
  );
};

export default TaskItem;
