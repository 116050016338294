import { useState, useEffect, useContext, useRef } from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { confirmAlert } from "react-confirm-alert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Button, Modal } from "react-bootstrap";
import {
  Snackbar,
  Alert,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import { ATLAS_URI } from "../../../../Constants";
import { axiosFetch } from "../../../../Utlis/AxiosFetch";
import { useHistory } from "react-router-dom";

import stateContext from "../../../../context/StateContext";

import "./ClientWorkoutPlan.css";
import Calendar from "./Calendar";

import WorkoutModel from "./WorkoutModel";
import AddExercise from "./AddExercise";

import { useSelector, useDispatch } from "react-redux";
import {
  resetVmaxList,
  updateStartDate,
  updateEditWorkout,
  updateDeleteUpdateEvent,
  updateEndDate,
} from "../../../store/WorkoutPlan/WorkoutPlan";
import axios from "axios";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DatePicker } from "@mui/x-date-pickers";
import { useParams } from "react-router-dom";
import Index from "../../../../Utlis/Text Editor/Index";

const ClientWorkoutPlan = (props) => {
  const dispatch = useDispatch();
  let workoutClientId = useParams();

  const id = JSON.parse(window.localStorage.getItem("operator")).id;
  const { rowDetails } = props;

  const [childComponent, setChildComponent] = useState(false);
  const clientId = useSelector((state) => state.leadId);

  const stateS = useSelector((state) => state);

  const workoutPlan = useSelector((state) => state.workoutPlan);

  const plan = useSelector((state) => state.workoutPlan.startDate);

  const editWorkout = useSelector((state) => state.workoutPlan.editWorkout);
  const clickEvent = useSelector((state) => state.workoutPlan.clickEvent);
  const viewTemplate = useSelector((state) => state.workoutPlan.viewTemplate);
  const [startFlag, setStartFlag] = useState(true);
  const [viewFlag, setViewFlag] = useState(false);

  const updateTemplateEvent = useSelector(
    (state) => state.workoutPlan.deleteUpdateEvent
  );

  const [workoutTypeCate, setWorkoutTypeCate] = useState("");
  const contextState = useContext(stateContext);
  const { operator, configToken } = contextState.state;
  const [showModel, setShowModel] = useState(false);
  const [showExerciseModal, setExerciseModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackMessage, setsnackMessage] = useState("");
  const [workoutTemplate_list, setWorkoutTemplate_list] = useState([]);
  const [templateName, setTemplateName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [restEvents, setRestEvents] = useState(false);

  const [snackBarColor, setSnackBarColor] = useState("");

  const planName = useRef("");
  const [planType, setPlanType] = useState("");

  const calBurn = useRef("");

  const [workoutPlanName, setWorkoutPlanName] = useState("");
  const [targetCalorie, setTargetCalorie] = useState("");

  const [templateListId, setTemplateListId] = useState("");
  const [clientData, setClientDate] = useState({});

  const notallow = useRef("");
  const [openPDF, setOpenPDF] = useState(false);
  const [showplanNotes, setShowplanNotes] = useState(false);
  const [mainPlanNotes, setMainPlanNotes] = useState("");

  const [target_calorie, setTarget_calorie] = useState("");
  const [InstructionNote, SetInstructionNote] = useState("");
  const [isEdit, setEdit] = useState(false);
  const params = useParams();

  const deletePlanNote = () => {
    setTarget_calorie("");
    setMainPlanNotes("");
    SetInstructionNote("");
  };
  const deleteNote = () => {
    confirmAlert({
      title: "Confirm to submit",
      message: `Are you sure you want to delete `,
      buttons: [
        {
          label: "Yes",
          onClick: () => deletePlanNote(),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const saveMainDietPlanNotes = async () => {
    setIndexdb(target_calorie);
    setMainPlanNotes(target_calorie);
    setShowplanNotes(false);
  };

  const setIndexdb = (htmlContent) => {
    const blob = new Blob([htmlContent], { type: "text/html" });
    const formData = new FormData();
    formData.append("index", blob, "instructions.html");
    SetInstructionNote(formData);
  };

  useOnClickOutside(
    notallow,
    () => {
      if (
        workoutPlan.vmaxList.length > 0 ||
        workoutPlan.customList.length > 0 ||
        workoutPlan.liveList.length > 0 ||
        workoutPlan.restList.length > 0
      ) {
        alert("Please save the plan to continue or your data will be deleted");
      }
    },
    !showModel && !showExerciseModal
  );
  useEffect(() => {
    let clientId = workoutClientId.id;
    //Get All Clients
    axios
      .get(`${ATLAS_URI}/getProfileDetailsById/` + clientId, configToken)
      .then((response) => {
        setClientDate(response.data);
      })
      .catch((err) => console.error(err));
  }, []);
  useEffect(() => {
    if (viewTemplate) {
      setViewFlag(true);
    }
  }, [viewTemplate]);

  useEffect(() => {
    const dateFormat = (date) => {
      if (date) {
        const dateObject = new Date(date);
        const year = dateObject.getFullYear();
        const month = String(dateObject.getMonth() + 1).padStart(2, "0");
        const day = String(dateObject.getDate()).padStart(2, "0");

        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
      }

      return date;
    };
    // console.log(`changing ${dateFormat(endDate)}`)
    let plans = workoutPlan.customList;
    const dates = plans.map((plan) => new Date(plan.date));

    // Find the maximum date
    const maxDate = new Date(Math.max(...dates));

    if (maxDate) {
      if (new Date(endDate).getTime() < new Date(maxDate).getTime() && isEdit) {
        setOpen(true);
        setsnackMessage("Changing End Date");
        setSnackBarColor("warning");
        // setEndDate(new Date(maxDate))
        setEndDate((prevEndDate) => dayjs(maxDate));
      }
    }
  }, [workoutPlan]);
  let history = useHistory();

  let currentWorkOut;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosFetch({
          url: `/getWorkoutPlanTemplate`,
          method: "get",
        });

        setWorkoutTemplate_list(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (props.rowDetails?.end_date) {
      setEndDate(dayjs(props?.rowDetails?.end_date));
    }
    if (plan.planStart) {
      setStartDate(dayjs(plan.planStart));
      setTemplateName(plan.templateName);
      // setWorkoutPlanName(plan.planName);
      setTargetCalorie(plan.targetCalorie);
    }
  }, [plan]);

  useEffect(() => {
    return () => {
      if (props.templateFlag) {
        props.restFunction();
      }

      dispatch(resetVmaxList());
      dispatch(updateEditWorkout(""));
      dispatch(updateStartDate({ date: "", planName: "" }));
      dispatch(updateEndDate({ date: "", planName: "" }));
    };
  }, []);

  useEffect(() => {
    dispatch(
      updateStartDate({
        ...plan,
        planName: planType,
        targetCalorie: targetCalorie,
      })
    );
  }, [planType, targetCalorie]);

  const handleCloseSnackbar = () => {
    setOpen(false);
  };

  const handleOpenSnackbar = (value) => {
    setOpen(true);
    setsnackMessage(value);
  };

  const modelOpen = (value) => {
    setShowModel(value);
  };

  const closeModel = () => {
    setShowModel(false);
    setExerciseModal(false);
  };
  const closeNotes = () => {
    setShowplanNotes(false);
  };

  const handleSelectChange = (e) => {
    setTemplateListId(e.target.value);

    setWorkoutTypeCate(e.target.value);
    [currentWorkOut] = workoutTemplate_list.filter((data) => {
      return +e.target.value === data.id;
    });
  };

  function handleNextMonthDate(selectedDate) {
    const date = new Date(selectedDate.$d);
    const formattedDate = date
      .toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .replace(/\//g, "-");

    const DateStr = formattedDate;
    const daysToAdd = 31;

    const Dates = new Date(DateStr);

    Dates.setDate(Dates.getDate() + daysToAdd);

    const nextDateStr = `${
      Dates.getMonth() + 1
    }-${Dates.getDate()}-${Dates.getFullYear()}`;

    return [nextDateStr, formattedDate];
  }

  const hangleTargetCalorie = (e) => {
    let calor = e.target.value;
    const inputValues = calor.replace(/^0+/, "");
    const numericValue = parseInt(inputValues, 10) || 0;
    if (numericValue >= 1 && numericValue <= 999) {
      setTargetCalorie(numericValue);
    } else {
      setTargetCalorie("");
    }
  };

  const handleDatePicker = (selectedDate, flag) => {
    if (flag === "enddate") {
      const [nextDateStr, formattedDate] = handleNextMonthDate(selectedDate);

      dispatch(
        updateEndDate({
          date: nextDateStr,
          planName: planName.current.value
            ? planName.current.value
            : workoutPlanName,
          planEnd: formattedDate,
          templateName: templateName,
        })
      );
      setEndDate((prevEndDate) => dayjs(formattedDate));
    } else {
      const [nextDateStr, formattedDate] = handleNextMonthDate(selectedDate);
      dispatch(
        updateStartDate({
          date: nextDateStr,
          planName: planType,
          planStart: formattedDate,
          templateName: templateName,
          targetCalorie: targetCalorie,
        })
      );

      setStartDate(dayjs(formattedDate));
      setStartFlag(false);
    }
  };

  const handleTemplateName = (e) => {
    setTemplateName(e.target.value);
  };

  const handleStartandEndDate = () => {
    let startDateStr = new Date(startDate).getTime();
    let endDateStr = new Date(endDate).getTime();

    if (endDateStr < startDateStr && endDate !== null) {
      setsnackMessage("End Date previous of Start Date not accepected");
      setSnackBarColor("warning");
      setOpen(true);
      return false;
    } else if (endDateStr === startDateStr) {
      setsnackMessage("End Date and Start Date should not be same");
      setSnackBarColor("warning");
      setOpen(true);
      return false;
    }
    return true;
  };
  const getAllplans = (flag) => {
    const dateFormat = (date) => {
      if (date) {
        if (editWorkout) {
          //   const dateObject = new Date(date);
          //   const year = dateObject.getFullYear();
          //   const month = String(dateObject.getMonth() + 1).padStart(2, "0");
          //   const day = String(dateObject.getDate()).padStart(2, "0");

          //   const formattedDate = `${year}-${month}-${day}`;
          //   return formattedDate;
          // } else {
          const dateObject = new Date(date);
          const year = dateObject.getFullYear();
          const month = String(dateObject.getMonth() + 1).padStart(2, "0");
          const day = String(dateObject.getDate()).padStart(2, "0");

          const formattedDate = `${year}-${month}-${day}`;
          return formattedDate;
        }
      }

      return date;
    };

    let allPlan;

    allPlan = {
      start_date: dateFormat(startDate),
      end_date: dateFormat(endDate),
      plan_name: planType ? planType : workoutPlanName,
      target_calorie: targetCalorie,
      userId: operator.id,
      leadId: workoutClientId.id,
      relationship_data: {
        vmax: workoutPlan.vmaxList ? workoutPlan.vmaxList : [],
        live: workoutPlan.liveList ? workoutPlan.liveList : [],
        custom: workoutPlan.customList ? workoutPlan.customList : [],
        rest: workoutPlan.restList ? workoutPlan.restList : [],
      },
    };

    if (editWorkout) {
      allPlan.id = editWorkout.id ? editWorkout.id : editWorkout;
      allPlan.updatedBy = id;
      delete allPlan.userId;
    }

    if (props.templateFlag) {
      allPlan.template_name = templateName;
      allPlan.userId = +id;
      delete allPlan.plan_name;
      delete allPlan.target_calorie;
      delete allPlan.leadId;
    } else if (flag) {
      allPlan.template_name = allPlan.plan_name;
      allPlan.userId = +id;
      delete allPlan.plan_name;
      delete allPlan.target_calorie;
      delete allPlan.leadId;
    }

    return allPlan;
  };

  const saveAsTemplate = async (flag, props) => {
    if (editWorkout ? true : handleStartandEndDate()) {
      let typeCheck = editWorkout
        ? true
        : workoutPlan.vmaxList.length > 0 ||
          workoutPlan.liveList.length > 0 ||
          workoutPlan.customList.length > 0 ||
          workoutPlan.restList.length > 0;

      let allPlan;

      if (flag) {
        allPlan = getAllplans(flag);
      } else {
        allPlan = getAllplans();
      }

      let response;

      if (typeCheck) {
        response = await axiosFetch({
          url: `/postWorkoutTemplate`,
          requestConfig: allPlan,
          method: "post",
        });
      } else {
        setsnackMessage("You can't save the plan without adding any workout");
        setSnackBarColor("warning");
        setOpen(true);
        return;
      }

      if (response) {
        if (response.status === 200) {
          dispatch(resetVmaxList());
          setRestEvents(true);
          setsnackMessage(response.data.message);
          setOpen(true);
          setSnackBarColor("success");
          if (flag === "workout-template") {
            setTimeout(() => {
              props.setShowDetailPage(false);
            }, 2000);
          } else {
            setTimeout(() => {
              props.handleTemplate();
            }, 2000);
          }
        } else {
          setOpen(true);
          setSnackBarColor("error");
          setsnackMessage(response.data.message);
        }
      }
    }
  };

  const savePlan = async () => {
    let allPlan = getAllplans();

    if (
      startDate === "" ||
      planName.current.value === "" ||
      calBurn.current.value === ""
    ) {
      setsnackMessage("Please provide all data for saving a workout plan");
      setSnackBarColor("warning");
      setOpen(true);
      return;
    }

    if (planName.current.value.match(/[^a-zA-Z0-9 ]/)) {
      setsnackMessage("Workout Plan Name Should Not Have Special Characters");
      setSnackBarColor("warning");
      setOpen(true);
      return;
    }
    if (startDate == "") {
      setsnackMessage("Please Select Valid Start Date");
      setSnackBarColor("warning");
      setOpen(true);
      return;
    }
    if (handleStartandEndDate()) {
      if (!updateTemplateEvent) {
        let response;

        let typeCheck = editWorkout
          ? true
          : workoutPlan.vmaxList.length > 0 ||
            workoutPlan.liveList.length > 0 ||
            workoutPlan.customList.length > 0 ||
            workoutPlan.restList.length > 0;

        if (typeCheck) {
          allPlan.instruction = mainPlanNotes;
          response = await axiosFetch({
            url: `/postWorkoutPlans`,
            requestConfig: allPlan,
            method: "post",
          });
        } else {
          setsnackMessage("You can't save the plan without adding any event");
          setSnackBarColor("warning");
          setOpen(true);
        }

        if (response) {
          if (response.status === 200) {
            if (InstructionNote) {
              axios.post(
                `${ATLAS_URI}/uploadPlanNote?workout=true&leadId=${params.id}&planId=${response.data.planId}`,
                InstructionNote,
                {
                  headers: configToken["headers"],
                }
              );
              dispatch(resetVmaxList());
              setsnackMessage(response.data.message);
              setOpen(true);
              setSnackBarColor("success");
              setTimeout(() => {
                props.handleChildClose();
              }, 2000);
              setChildComponent(true);
            } else {
              axios.post(
                `${ATLAS_URI}/uploadPlanNote?workout=true&leadId=${params.id}&planId=${response.data.planId}&isEmpty=true`,
                InstructionNote,
                {
                  headers: configToken["headers"],
                }
              );
              dispatch(resetVmaxList());
              setsnackMessage(response.data.message);
              setOpen(true);
              setSnackBarColor("success");
              setTimeout(() => {
                props.handleChildClose();
              }, 2000);
              setChildComponent(true);
            }
          } else {
            // dispatch(resetVmaxList());
            setOpen(true);
            setSnackBarColor("error");
            setsnackMessage("Plan is not created please try again");
          }
        }
      } else {
        if (updateTemplateEvent.idList.length > 0) {
          const deleteOldEvent = async (id) => {
            let response = await axiosFetch({
              url: `/postWorkoutPlans`,
              requestConfig: {
                action: "delete",
                workoutTypeId: id,
              },
              method: "post",
            });
          };

          updateTemplateEvent.idList.map((id) => {
            deleteOldEvent(id);
          });
        }

        let [originalObject] = updateTemplateEvent.updateTemplateList;
        let shallowCopy = { ...originalObject };

        shallowCopy.leadId = allPlan.leadId;
        shallowCopy.plan_name = allPlan.plan_name;
        shallowCopy.target_calorie = allPlan.target_calorie;
        shallowCopy.userId = allPlan.userId;
        shallowCopy.start_date = allPlan.start_date;
        shallowCopy.end_date = allPlan.end_date;
        shallowCopy.instruction = mainPlanNotes;

        let response = await axiosFetch({
          url: `/postWorkoutPlans`,
          requestConfig: shallowCopy,
          method: "post",
        });

        if (response.status === 200) {
          if (InstructionNote) {
            axios.post(
              `${ATLAS_URI}/uploadPlanNote?workout=true&leadId=${params.id}&planId=${response.data.planId}`,
              InstructionNote,
              {
                headers: configToken["headers"],
              }
            );
            dispatch(resetVmaxList());
            setsnackMessage(response.data.message);
            setOpen(true);
            setSnackBarColor("success");
            dispatch(updateDeleteUpdateEvent(false));
            setTimeout(() => {
              props.handleChildClose();
            }, 2000);
            setChildComponent(true);
          } else {
            dispatch(resetVmaxList());
            setsnackMessage(response.data.message);
            setOpen(true);
            setSnackBarColor("success");
            dispatch(updateDeleteUpdateEvent(false));
            setTimeout(() => {
              props.handleChildClose();
            }, 2000);
            setChildComponent(true);
          }
        } else {
          // dispatch(resetVmaxList());
          setOpen(true);
          setSnackBarColor("error");

          if (response.data.message === "Plan name exists already")
            setsnackMessage("Plan name exists already");
          else setsnackMessage("Plan is not created please try again");
        }
      }
    }
  };

  const openWorkoutPlanModal = (pdfLink) => {
    if (pdfLink) if (openPDF) window.open(pdfLink, "_blank");
  };

  const handleChildComponent = () => {
    setChildComponent(false);
  };

  const handleChange = (e) => {
    if (!e.target.value.match(/^[!@#$%^&*()_+-=[\]{};:'",<>./?\\|`~]*$/)) {
      setPlanType(e.target.value);
    }
  };

  let dietAllergies = [];
  clientData?.dietPreference?.dietAnyAllergies &&
    dietAllergies.push(clientData?.dietPreference?.dietAnyAllergies);
  clientData?.dietPreference?.otherAllergies &&
    dietAllergies.push(clientData?.dietPreference?.otherAllergies);

    const DATE_FORMAT='DD/MM/YYYY'
  return (
    <div>
      <div className={`workout-plan-con  ${props.templateFlag && `template`}`}>
        {props.templateFlag && (
          <div className="temlate-container">
            <div className="template-title-con">
              <div className="temlate-plan-col">
                <label htmlFor="programName">Template name</label>
                <input
                  placeholder="Template name"
                  type="text"
                  id="programName"
                  className="input"
                  disabled={viewFlag}
                  defaultValue={templateName}
                  onChange={handleTemplateName}
                />
              </div>

              <div className="temlate-plan-col date-picker-con">
                <label htmlFor="startDate">Start date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DesktopDatePicker
                      disabled={viewFlag}
                      onChange={handleDatePicker}
                      value={startDate}
                      
                    />
                  </DemoItem>
                </LocalizationProvider>
              </div>
            </div>

            {props.templateFlag && (
              <>
                {!viewFlag && (
                  <>
                    {editWorkout ? (
                      <button
                        className="template-btn"
                        onClick={() => saveAsTemplate("", props)}
                      >
                        Update
                      </button>
                    ) : (
                      <button
                        className="template-btn"
                        onClick={() => saveAsTemplate("", props)}
                      >
                        Save as Template
                      </button>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        )}

        {!props.templateFlag && (
          <>
            <div className="workout-plan-row border-shadow">
              <div className="workout-plan-col">
                <label htmlFor="programName">Target cal to burn</label>
                <input
                  className="custom-input"
                  placeholder="Target cal"
                  type="number"
                  id="programName"
                  ref={calBurn}
                  value={targetCalorie}
                  onChange={hangleTargetCalorie}
                  onKeyPress={(e) => {
                    if (e.key === "+" || e.key === "-") {
                      e.preventDefault();
                    }
                  }}
                  min="1"
                  max="999"
                />
              </div>
              <div className="workout-plan-col" style={{ width: "80%" }}>
                <label>Instructions</label>
                <div style={{ width: "100%" }}>
                  <div
                    className="instructionsWorkout"
                    style={{
                      width: "100%",
                      color: "grey",
                      background: "none",
                      textAlign: "left",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p
                      role="button"
                      tabIndex="0"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "0px",
                        flex: "1",
                      }}
                      onClick={() => setShowplanNotes(true)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === " ") {
                          setShowplanNotes(true);
                        }
                      }}
                    >
                      {mainPlanNotes !== "" ? (
                        <div
                          dangerouslySetInnerHTML={{ __html: mainPlanNotes }}
                        />
                      ) : (
                        <span>Enter Instructions</span>
                      )}
                    </p>
                    <button
                      className="deleteButton"
                      style={{ border: "none" }}
                      onClick={() => {
                        deletePlanNote();
                      }}
                    >
                      <DeleteOutlineIcon />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <Accordion
              id="border-shadow"
              style={{ gap: "20", padding: "8px", marginBottom: "32px" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography style={{ fontSize: "20px" }}>
                  Personal Information
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div class="flex-container">
                    <div class="flex-item">
                      <p>Diet Preference:</p>
                      <p>
                        {clientData?.dietPreference?.dietPreference
                          ? clientData.dietPreference?.dietPreference
                          : "--"}
                      </p>
                    </div>
                    <div class="flex-item">
                      <p>Preferred Cuisine:</p>
                      <p>
                        {clientData?.dietPreference?.dietPreferredCuisine
                          ? Array.isArray(
                              clientData.dietPreference.dietPreferredCuisine
                            )
                            ? clientData.dietPreference.dietPreferredCuisine.join(
                                ", "
                              )
                            : clientData.dietPreference.dietPreferredCuisine
                          : "--"}
                      </p>
                    </div>
                    <div class="flex-item">
                      <p>Medical Condition:</p>
                      <p>
                        {(clientData?.medicalAssessment
                          ?.current_medical_condition
                          ? clientData?.medicalAssessment?.current_medical_condition.join(
                              ", "
                            )
                          : null) ?? "--"}
                      </p>
                    </div>

                    <div class="flex-item">
                      <p>Allergies:</p>
                      <p>{dietAllergies ? dietAllergies.join(", ") : "--"}</p>
                    </div>

                    <div class="flex-item">
                      <p>Daily Activity:</p>
                      <p>
                        {clientData?.workoutPreferences?.active_you_are?.title
                          ? clientData?.workoutPreferences?.active_you_are
                              ?.title
                          : "--"}
                      </p>
                    </div>
                    <div class="flex-item">
                      <p>Current Weight:</p>
                      <p>
                        {clientData?.basicInfo?.current_weight
                          ? `${clientData.basicInfo.current_weight} ${
                              clientData.basicInfo.weight_unit === 0
                                ? "Kg"
                                : "lbs"
                            }`
                          : "--"}
                      </p>
                    </div>
                    <div class="flex-item">
                      <p>Target Weight:</p>
                      <p>
                        {clientData?.basicInfo?.weight_goal
                          ? `${clientData.basicInfo.weight_goal} ${
                              clientData.basicInfo.weight_unit === 0
                                ? "Kg"
                                : "lbs"
                            }`
                          : "--"}
                      </p>
                    </div>
                    <div class="flex-item">
                      <p>Goal:</p>
                      <p>
                        {clientData?.others?.fitness_goals
                          ? clientData?.others?.fitness_goals
                          : "--"}
                      </p>
                    </div>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <div
              className="workout-plan-row border-shadow"
              style={{ flexWrap: "wrap" }}
            >
              <div className="workout-plan-col">
                <input
                  autoComplete="off"
                  placeholder="Workout Plan Name"
                  type="text"
                  id="programName"
                  defaultValue={workoutPlanName}
                  ref={planName}
                  onChange={handleChange}
                />
              </div>

              <div className="workout-plan-col date-picker-con" id="datePicker">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem className="datePick">
                    <DesktopDatePicker
                      disablePast
                      className="date-picker-costum"
                      onChange={handleDatePicker}
                      value={startDate}
                      label="Start Date"
                      // format={DATE_FORMAT}
                    />
                  </DemoItem>
                </LocalizationProvider>
              </div>

              <div className="workout-plan-col date-picker-con" id="datePicker">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DesktopDatePicker
                      className="date-picker-costum"
                      onChange={(e) => handleDatePicker(e, "enddate")}
                      value={endDate}
                      label="End Date"
                      // format={DATE_FORMAT}
                    />
                  </DemoItem>
                </LocalizationProvider>
              </div>

              <div className="workout-plan-col">
                {/* <select
                  onChange={handleSelectChange}
                  value={workoutTypeCate}
                  disabled={startFlag}
                >
                  <option defaultValue="">Select Template</option>
                  {workoutTemplate_list &&
                    workoutTemplate_list.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.template_name}
                      </option>
                    ))}
                </select> */}
              </div>
              <button
                style={{ backgroundColor: "green" }}
                onClick={savePlan}
                className="btn-con-button"
              >
                {editWorkout ? "Update" : "Save"}
              </button>
              {<h1>{props.templateFlag}</h1>}
              {/* {!props.templateFlag && (
                <button
                  className="btn-con-button"
                  onClick={() => {
                    saveAsTemplate("workout-template", props);
                  }}
                >
                  Save as Template
                </button>
              )} */}
              {props.templateFlag && (
                <button
                  onClick={() => saveAsTemplate("", props)}
                  className="btn-con-button"
                >
                  Save as Template
                </button>
              )}
              <button
                className="btn-con-button"
                onClick={() => {
                  setOpenPDF(true);
                  openWorkoutPlanModal(rowDetails.pdfLink);
                }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18 7V5.2C18 4.0799 18 3.51984 17.782 3.09202C17.5903 2.71569 17.2843 2.40973 16.908 2.21799C16.4802 2 15.9201 2 14.8 2H9.2C8.0799 2 7.51984 2 7.09202 2.21799C6.71569 2.40973 6.40973 2.71569 6.21799 3.09202C6 3.51984 6 4.0799 6 5.2V7M6 18C5.07003 18 4.60504 18 4.22354 17.8978C3.18827 17.6204 2.37962 16.8117 2.10222 15.7765C2 15.395 2 14.93 2 14V11.8C2 10.1198 2 9.27976 2.32698 8.63803C2.6146 8.07354 3.07354 7.6146 3.63803 7.32698C4.27976 7 5.11984 7 6.8 7H17.2C18.8802 7 19.7202 7 20.362 7.32698C20.9265 7.6146 21.3854 8.07354 21.673 8.63803C22 9.27976 22 10.1198 22 11.8V14C22 14.93 22 15.395 21.8978 15.7765C21.6204 16.8117 20.8117 17.6204 19.7765 17.8978C19.395 18 18.93 18 18 18M15 10.5H18M9.2 22H14.8C15.9201 22 16.4802 22 16.908 21.782C17.2843 21.5903 17.5903 21.2843 17.782 20.908C18 20.4802 18 19.9201 18 18.8V17.2C18 16.0799 18 15.5198 17.782 15.092C17.5903 14.7157 17.2843 14.4097 16.908 14.218C16.4802 14 15.9201 14 14.8 14H9.2C8.0799 14 7.51984 14 7.09202 14.218C6.71569 14.4097 6.40973 14.7157 6.21799 15.092C6 15.5198 6 16.0799 6 17.2V18.8C6 19.9201 6 20.4802 6.21799 20.908C6.40973 21.2843 6.71569 21.5903 7.09202 21.782C7.51984 22 8.07989 22 9.2 22Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </>
        )}
      </div>
      <Calendar
        templateListId={templateListId}
        editTemplateFlag={props.editTemplateFlag}
        templateFlag={props.templateFlag}
        modelOpen={modelOpen}
        handleOpenSnackbar={handleOpenSnackbar}
        childComponent={childComponent}
        handleChildComponent={handleChildComponent}
        restEvents={restEvents}
        targetCalorie={+calBurn.current.value}
        setTarget_calorie={setTarget_calorie}
        setMainPlanNotes={setMainPlanNotes}
        setIndexdb={setIndexdb}
        setEdit={setEdit}
        setWorkoutPlanName={setWorkoutPlanName}
      />
      {showplanNotes ? (
        <>
          <Modal
            show={showplanNotes}
            style={{
              width: "100%",
              justifyContent: "center",
              position: "relative",
              transform: "translateY(-1000px)",
              height: "1000px",
            }}
          >
            <Modal.Title>&nbsp; Instructions</Modal.Title>

            <Modal.Body>
              <Index
                setTarget_calorie={setTarget_calorie}
                target_calorie={target_calorie}
                showplanNotes={showplanNotes}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{ backgroundColor: "black", color: "white" }}
                onClick={() => saveMainDietPlanNotes()}
              >
                Save
              </Button>
              <Button onClick={() => closeNotes()} variant="secondary">
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        ""
      )}
      {showModel && <WorkoutModel closeModel={closeModel} />}
      {showExerciseModal && <AddExercise closeModel={closeModel} />}
      <Snackbar
        className="snackbar-container"
        open={open}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          style={{ fontWeight: "bold", fontSize: "19px" }}
          severity={snackBarColor}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ClientWorkoutPlan;

function useOnClickOutside(ref, handler, enabled) {
  useEffect(() => {
    if (!enabled) return;
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}
